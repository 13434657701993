import React from 'react'
import { useOngoingWorkSubscription } from '../generated/graphql'
import { useTime } from '../hooks/useTime'
import moment from 'moment'

export const OngoingWorkExtra: React.FC<{}> = () => {
    const {data} = useOngoingWorkSubscription()

    const now = useTime(1000)
    if (!data) {
        return <div></div>
    }
    const period = data.activity_period[0]
    const contractor = period.work_units[0].contractor
    const hours = moment.duration(moment(now).diff(moment(period.start_time))).asHours()
    const taxfix = 0.81
    const money = hours * contractor.hourly_wage * taxfix    

    return <div>
        {contractor.name} - {money.toFixed(2)} PLN
    </div>
}