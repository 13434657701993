import React from 'react'
import styled from 'styled-components'
import { Button, Modal, Select, DatePicker, TimePicker, Form, InputNumber, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { CURRENT_MEDITATIONS } from './Meditations'
import { Meditation_Type_Enum, useAddMeditationMutation } from '../generated/graphql'
import { Moment } from 'moment'

const Wrapper = styled.div`
    position: absolute;
    right: 10px;
    bottom: 10px;
`

const { Option } = Select

const format = 'HH:mm';

interface FormResult {
    type: Meditation_Type_Enum
    date: Moment
    start: Moment
    end: Moment
    repetitions?: number
}

export const AddSession: React.FC<{}> = () => {
    const [open, setOpen] = React.useState(false)
    const [mutate, status] = useAddMeditationMutation()

    const onConfirm = async (res: FormResult) => {

        const start_date = res.date.clone()
        start_date.set({
            hour: res.start.hour(),
            minute: res.start.minute(),
            second: 0
        })
        const end_date = res.date.clone()
        end_date.set({
            hour: res.end.hour(),
            minute: res.end.minute(),
            second: 0
        })

        try {
        const mutres = await mutate({
            variables: {
                date: res.date,
                start_time: start_date,
                end_time: end_date,
                type: res.type,
                repetitions: res.repetitions ?? undefined
            }
        })
        message.success("Practice added")
        }
        catch(error) {
            message.error(error.message)
        }
        finally{
            setOpen(false)
        }
    }

    return <Wrapper>
        <Button icon={<PlusOutlined />} shape="circle" onClick={() => setOpen(true)} />
        <Modal
            title={"Register practice"}
            visible={open}
            okText={"Save"}
            onCancel={() => setOpen(false)}
            okButtonProps={{form: 'add-meditations-form', htmlType: 'submit'}}            
        >
            <Form
                id="add-meditations-form"
                onFinish={(values) => onConfirm(values as FormResult)}
                
            >
                <Form.Item label="Meditation type" name="type"
                    rules={[{required: true}]}
                >
                    <Select>
                        {
                            CURRENT_MEDITATIONS.map(m => <Option key={m} value={m}>{m}</Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item label="Date" name="date"
                    rules={[{required: true}]}
                >
                    <DatePicker />
                </Form.Item>
                <Form.Item label="Time started" name="start"
                    rules={[{required: true}]}
                >
                    <TimePicker format={format} minuteStep={5} />
                </Form.Item>

                <Form.Item label="Time finished" name="end"
                    rules={[{required: true}]}
                >
                    <TimePicker format={format} minuteStep={5} />
                </Form.Item>
                <Form.Item label="Repetitions" name="repetitions">
                    <InputNumber />
                </Form.Item>
            </Form>
        </Modal>
    </Wrapper>
}