import React from 'react'
import styled from 'styled-components'
import { UserOutlined, LaptopOutlined, SkinOutlined } from '@ant-design/icons'
import { SELECTED_COLOR } from './base_colors'
import { useLocation, useHistory } from 'react-router-dom'

const MenuWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-self: flex-end;
    justify-content: space-between;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    padding-top: 5px;
    padding-bottom: 5px;
`

const MenuItemWrapper = styled.div<{shine: boolean}>`
    flex: 1 1 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & svg {
        font-size: 20px;
    }
    color: ${({shine}) => shine ? SELECTED_COLOR : "inherit"};
    transition: all 200ms;
    cursor: pointer;
`

const MenuItem: React.FC<{target: string}> = ({target, children}) => {
    const history = useHistory()
    const location = useLocation()
    
    const handleClick = () => {
        history.push(`/${target}`)
    }
    const shine = location.pathname.includes(target)

    return <MenuItemWrapper shine={shine} onClick={handleClick}>
        {children}
    </MenuItemWrapper>
}

export const BottomMenu: React.FC<{}> = () => {

    return <MenuWrapper>
        <MenuItem target="practice">
            <UserOutlined />
            Practice
        </MenuItem>        
        <MenuItem target="work">
            <LaptopOutlined />
            Work
        </MenuItem>
        <MenuItem target="body">
            <SkinOutlined />
            Body
        </MenuItem>
    </MenuWrapper>
}