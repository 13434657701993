import React from 'react';
import { Meditations } from './meditation/Meditations';
import 'antd/dist/antd.css';
import styled from 'styled-components';
import { BottomMenu } from './BottomMenu';
import { BACKGROUND_COLOR, TEXT_COLOR } from './base_colors';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { Body } from './body/Body';
import { OngoingInfo } from './ongoing/OngoingInfo';
import { Work } from './work/Work';

const AppWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${BACKGROUND_COLOR};
  color: ${TEXT_COLOR};
`

const ContentWrapper = styled.div`
  flex-grow: 1;
`

function App() {
  return (
    <Router>
      <AppWrapper>
      <ContentWrapper>
          <Switch>
            <Route path="/work">
              <Work />
            </Route>    
            <Route path="/practice">
              <Meditations />
            </Route>            
            <Route path="/body">
              <Body />
            </Route>
          </Switch>
          </ContentWrapper>
          <OngoingInfo />
          <BottomMenu />
      </AppWrapper>
    </Router>
  );
}

export default App;
