import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  date: any;
  interval: any;
  timestamptz: any;
  timetz: any;
  uuid: any;
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

/** columns and relationships of "activity_period" */
export type Activity_Period = {
  __typename?: 'activity_period';
  activity_type_id: Period_Type_Enum;
  end_time?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An array relationship */
  meditations: Array<Meditation>;
  /** An aggregated array relationship */
  meditations_aggregate: Meditation_Aggregate;
  start_time: Scalars['timestamptz'];
  /** An array relationship */
  work_units: Array<Work_Unit>;
  /** An aggregated array relationship */
  work_units_aggregate: Work_Unit_Aggregate;
};


/** columns and relationships of "activity_period" */
export type Activity_PeriodMeditationsArgs = {
  distinct_on?: Maybe<Array<Meditation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Meditation_Order_By>>;
  where?: Maybe<Meditation_Bool_Exp>;
};


/** columns and relationships of "activity_period" */
export type Activity_PeriodMeditations_AggregateArgs = {
  distinct_on?: Maybe<Array<Meditation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Meditation_Order_By>>;
  where?: Maybe<Meditation_Bool_Exp>;
};


/** columns and relationships of "activity_period" */
export type Activity_PeriodWork_UnitsArgs = {
  distinct_on?: Maybe<Array<Work_Unit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Work_Unit_Order_By>>;
  where?: Maybe<Work_Unit_Bool_Exp>;
};


/** columns and relationships of "activity_period" */
export type Activity_PeriodWork_Units_AggregateArgs = {
  distinct_on?: Maybe<Array<Work_Unit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Work_Unit_Order_By>>;
  where?: Maybe<Work_Unit_Bool_Exp>;
};

/** aggregated selection of "activity_period" */
export type Activity_Period_Aggregate = {
  __typename?: 'activity_period_aggregate';
  aggregate?: Maybe<Activity_Period_Aggregate_Fields>;
  nodes: Array<Activity_Period>;
};

/** aggregate fields of "activity_period" */
export type Activity_Period_Aggregate_Fields = {
  __typename?: 'activity_period_aggregate_fields';
  avg?: Maybe<Activity_Period_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Activity_Period_Max_Fields>;
  min?: Maybe<Activity_Period_Min_Fields>;
  stddev?: Maybe<Activity_Period_Stddev_Fields>;
  stddev_pop?: Maybe<Activity_Period_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Activity_Period_Stddev_Samp_Fields>;
  sum?: Maybe<Activity_Period_Sum_Fields>;
  var_pop?: Maybe<Activity_Period_Var_Pop_Fields>;
  var_samp?: Maybe<Activity_Period_Var_Samp_Fields>;
  variance?: Maybe<Activity_Period_Variance_Fields>;
};


/** aggregate fields of "activity_period" */
export type Activity_Period_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Activity_Period_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "activity_period" */
export type Activity_Period_Aggregate_Order_By = {
  avg?: Maybe<Activity_Period_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Activity_Period_Max_Order_By>;
  min?: Maybe<Activity_Period_Min_Order_By>;
  stddev?: Maybe<Activity_Period_Stddev_Order_By>;
  stddev_pop?: Maybe<Activity_Period_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Activity_Period_Stddev_Samp_Order_By>;
  sum?: Maybe<Activity_Period_Sum_Order_By>;
  var_pop?: Maybe<Activity_Period_Var_Pop_Order_By>;
  var_samp?: Maybe<Activity_Period_Var_Samp_Order_By>;
  variance?: Maybe<Activity_Period_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "activity_period" */
export type Activity_Period_Arr_Rel_Insert_Input = {
  data: Array<Activity_Period_Insert_Input>;
  on_conflict?: Maybe<Activity_Period_On_Conflict>;
};

/** aggregate avg on columns */
export type Activity_Period_Avg_Fields = {
  __typename?: 'activity_period_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "activity_period" */
export type Activity_Period_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "activity_period". All fields are combined with a logical 'AND'. */
export type Activity_Period_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Activity_Period_Bool_Exp>>>;
  _not?: Maybe<Activity_Period_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Activity_Period_Bool_Exp>>>;
  activity_type_id?: Maybe<Period_Type_Enum_Comparison_Exp>;
  end_time?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  meditations?: Maybe<Meditation_Bool_Exp>;
  start_time?: Maybe<Timestamptz_Comparison_Exp>;
  work_units?: Maybe<Work_Unit_Bool_Exp>;
};

/** unique or primary key constraints on table "activity_period" */
export enum Activity_Period_Constraint {
  /** unique or primary key constraint */
  ActivityPeriodPkey = 'activity_period_pkey'
}

/** input type for incrementing integer column in table "activity_period" */
export type Activity_Period_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "activity_period" */
export type Activity_Period_Insert_Input = {
  activity_type_id?: Maybe<Period_Type_Enum>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  meditations?: Maybe<Meditation_Arr_Rel_Insert_Input>;
  start_time?: Maybe<Scalars['timestamptz']>;
  work_units?: Maybe<Work_Unit_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Activity_Period_Max_Fields = {
  __typename?: 'activity_period_max_fields';
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "activity_period" */
export type Activity_Period_Max_Order_By = {
  end_time?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  start_time?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Activity_Period_Min_Fields = {
  __typename?: 'activity_period_min_fields';
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "activity_period" */
export type Activity_Period_Min_Order_By = {
  end_time?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  start_time?: Maybe<Order_By>;
};

/** response of any mutation on the table "activity_period" */
export type Activity_Period_Mutation_Response = {
  __typename?: 'activity_period_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Activity_Period>;
};

/** input type for inserting object relation for remote table "activity_period" */
export type Activity_Period_Obj_Rel_Insert_Input = {
  data: Activity_Period_Insert_Input;
  on_conflict?: Maybe<Activity_Period_On_Conflict>;
};

/** on conflict condition type for table "activity_period" */
export type Activity_Period_On_Conflict = {
  constraint: Activity_Period_Constraint;
  update_columns: Array<Activity_Period_Update_Column>;
  where?: Maybe<Activity_Period_Bool_Exp>;
};

/** ordering options when selecting data from "activity_period" */
export type Activity_Period_Order_By = {
  activity_type_id?: Maybe<Order_By>;
  end_time?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meditations_aggregate?: Maybe<Meditation_Aggregate_Order_By>;
  start_time?: Maybe<Order_By>;
  work_units_aggregate?: Maybe<Work_Unit_Aggregate_Order_By>;
};

/** primary key columns input for table: "activity_period" */
export type Activity_Period_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "activity_period" */
export enum Activity_Period_Select_Column {
  /** column name */
  ActivityTypeId = 'activity_type_id',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Id = 'id',
  /** column name */
  StartTime = 'start_time'
}

/** input type for updating data in table "activity_period" */
export type Activity_Period_Set_Input = {
  activity_type_id?: Maybe<Period_Type_Enum>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Activity_Period_Stddev_Fields = {
  __typename?: 'activity_period_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "activity_period" */
export type Activity_Period_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Activity_Period_Stddev_Pop_Fields = {
  __typename?: 'activity_period_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "activity_period" */
export type Activity_Period_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Activity_Period_Stddev_Samp_Fields = {
  __typename?: 'activity_period_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "activity_period" */
export type Activity_Period_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Activity_Period_Sum_Fields = {
  __typename?: 'activity_period_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "activity_period" */
export type Activity_Period_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "activity_period" */
export enum Activity_Period_Update_Column {
  /** column name */
  ActivityTypeId = 'activity_type_id',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Id = 'id',
  /** column name */
  StartTime = 'start_time'
}

/** aggregate var_pop on columns */
export type Activity_Period_Var_Pop_Fields = {
  __typename?: 'activity_period_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "activity_period" */
export type Activity_Period_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Activity_Period_Var_Samp_Fields = {
  __typename?: 'activity_period_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "activity_period" */
export type Activity_Period_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Activity_Period_Variance_Fields = {
  __typename?: 'activity_period_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "activity_period" */
export type Activity_Period_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "contractor" */
export type Contractor = {
  __typename?: 'contractor';
  hourly_wage: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** aggregated selection of "contractor" */
export type Contractor_Aggregate = {
  __typename?: 'contractor_aggregate';
  aggregate?: Maybe<Contractor_Aggregate_Fields>;
  nodes: Array<Contractor>;
};

/** aggregate fields of "contractor" */
export type Contractor_Aggregate_Fields = {
  __typename?: 'contractor_aggregate_fields';
  avg?: Maybe<Contractor_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Contractor_Max_Fields>;
  min?: Maybe<Contractor_Min_Fields>;
  stddev?: Maybe<Contractor_Stddev_Fields>;
  stddev_pop?: Maybe<Contractor_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contractor_Stddev_Samp_Fields>;
  sum?: Maybe<Contractor_Sum_Fields>;
  var_pop?: Maybe<Contractor_Var_Pop_Fields>;
  var_samp?: Maybe<Contractor_Var_Samp_Fields>;
  variance?: Maybe<Contractor_Variance_Fields>;
};


/** aggregate fields of "contractor" */
export type Contractor_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contractor_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contractor" */
export type Contractor_Aggregate_Order_By = {
  avg?: Maybe<Contractor_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Contractor_Max_Order_By>;
  min?: Maybe<Contractor_Min_Order_By>;
  stddev?: Maybe<Contractor_Stddev_Order_By>;
  stddev_pop?: Maybe<Contractor_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Contractor_Stddev_Samp_Order_By>;
  sum?: Maybe<Contractor_Sum_Order_By>;
  var_pop?: Maybe<Contractor_Var_Pop_Order_By>;
  var_samp?: Maybe<Contractor_Var_Samp_Order_By>;
  variance?: Maybe<Contractor_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contractor" */
export type Contractor_Arr_Rel_Insert_Input = {
  data: Array<Contractor_Insert_Input>;
  on_conflict?: Maybe<Contractor_On_Conflict>;
};

/** aggregate avg on columns */
export type Contractor_Avg_Fields = {
  __typename?: 'contractor_avg_fields';
  hourly_wage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contractor" */
export type Contractor_Avg_Order_By = {
  hourly_wage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contractor". All fields are combined with a logical 'AND'. */
export type Contractor_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Contractor_Bool_Exp>>>;
  _not?: Maybe<Contractor_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Contractor_Bool_Exp>>>;
  hourly_wage?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contractor" */
export enum Contractor_Constraint {
  /** unique or primary key constraint */
  ContractorPkey = 'contractor_pkey'
}

/** input type for incrementing integer column in table "contractor" */
export type Contractor_Inc_Input = {
  hourly_wage?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "contractor" */
export type Contractor_Insert_Input = {
  hourly_wage?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Contractor_Max_Fields = {
  __typename?: 'contractor_max_fields';
  hourly_wage?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "contractor" */
export type Contractor_Max_Order_By = {
  hourly_wage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Contractor_Min_Fields = {
  __typename?: 'contractor_min_fields';
  hourly_wage?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "contractor" */
export type Contractor_Min_Order_By = {
  hourly_wage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "contractor" */
export type Contractor_Mutation_Response = {
  __typename?: 'contractor_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Contractor>;
};

/** input type for inserting object relation for remote table "contractor" */
export type Contractor_Obj_Rel_Insert_Input = {
  data: Contractor_Insert_Input;
  on_conflict?: Maybe<Contractor_On_Conflict>;
};

/** on conflict condition type for table "contractor" */
export type Contractor_On_Conflict = {
  constraint: Contractor_Constraint;
  update_columns: Array<Contractor_Update_Column>;
  where?: Maybe<Contractor_Bool_Exp>;
};

/** ordering options when selecting data from "contractor" */
export type Contractor_Order_By = {
  hourly_wage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "contractor" */
export type Contractor_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "contractor" */
export enum Contractor_Select_Column {
  /** column name */
  HourlyWage = 'hourly_wage',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "contractor" */
export type Contractor_Set_Input = {
  hourly_wage?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Contractor_Stddev_Fields = {
  __typename?: 'contractor_stddev_fields';
  hourly_wage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contractor" */
export type Contractor_Stddev_Order_By = {
  hourly_wage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Contractor_Stddev_Pop_Fields = {
  __typename?: 'contractor_stddev_pop_fields';
  hourly_wage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contractor" */
export type Contractor_Stddev_Pop_Order_By = {
  hourly_wage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Contractor_Stddev_Samp_Fields = {
  __typename?: 'contractor_stddev_samp_fields';
  hourly_wage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contractor" */
export type Contractor_Stddev_Samp_Order_By = {
  hourly_wage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Contractor_Sum_Fields = {
  __typename?: 'contractor_sum_fields';
  hourly_wage?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "contractor" */
export type Contractor_Sum_Order_By = {
  hourly_wage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "contractor" */
export enum Contractor_Update_Column {
  /** column name */
  HourlyWage = 'hourly_wage',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Contractor_Var_Pop_Fields = {
  __typename?: 'contractor_var_pop_fields';
  hourly_wage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contractor" */
export type Contractor_Var_Pop_Order_By = {
  hourly_wage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Contractor_Var_Samp_Fields = {
  __typename?: 'contractor_var_samp_fields';
  hourly_wage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contractor" */
export type Contractor_Var_Samp_Order_By = {
  hourly_wage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Contractor_Variance_Fields = {
  __typename?: 'contractor_variance_fields';
  hourly_wage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contractor" */
export type Contractor_Variance_Order_By = {
  hourly_wage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};


/** expression to compare columns of type date. All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};


/** expression to compare columns of type interval. All fields are combined with logical 'AND'. */
export type Interval_Comparison_Exp = {
  _eq?: Maybe<Scalars['interval']>;
  _gt?: Maybe<Scalars['interval']>;
  _gte?: Maybe<Scalars['interval']>;
  _in?: Maybe<Array<Scalars['interval']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['interval']>;
  _lte?: Maybe<Scalars['interval']>;
  _neq?: Maybe<Scalars['interval']>;
  _nin?: Maybe<Array<Scalars['interval']>>;
};

/** columns and relationships of "meditation" */
export type Meditation = {
  __typename?: 'meditation';
  /** An object relationship */
  activity_period?: Maybe<Activity_Period>;
  date: Scalars['date'];
  id: Scalars['Int'];
  meditation_type_id: Meditation_Type_Enum;
  period_id?: Maybe<Scalars['Int']>;
  repetitions?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "meditation" */
export type Meditation_Aggregate = {
  __typename?: 'meditation_aggregate';
  aggregate?: Maybe<Meditation_Aggregate_Fields>;
  nodes: Array<Meditation>;
};

/** aggregate fields of "meditation" */
export type Meditation_Aggregate_Fields = {
  __typename?: 'meditation_aggregate_fields';
  avg?: Maybe<Meditation_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Meditation_Max_Fields>;
  min?: Maybe<Meditation_Min_Fields>;
  stddev?: Maybe<Meditation_Stddev_Fields>;
  stddev_pop?: Maybe<Meditation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Meditation_Stddev_Samp_Fields>;
  sum?: Maybe<Meditation_Sum_Fields>;
  var_pop?: Maybe<Meditation_Var_Pop_Fields>;
  var_samp?: Maybe<Meditation_Var_Samp_Fields>;
  variance?: Maybe<Meditation_Variance_Fields>;
};


/** aggregate fields of "meditation" */
export type Meditation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Meditation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "meditation" */
export type Meditation_Aggregate_Order_By = {
  avg?: Maybe<Meditation_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Meditation_Max_Order_By>;
  min?: Maybe<Meditation_Min_Order_By>;
  stddev?: Maybe<Meditation_Stddev_Order_By>;
  stddev_pop?: Maybe<Meditation_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Meditation_Stddev_Samp_Order_By>;
  sum?: Maybe<Meditation_Sum_Order_By>;
  var_pop?: Maybe<Meditation_Var_Pop_Order_By>;
  var_samp?: Maybe<Meditation_Var_Samp_Order_By>;
  variance?: Maybe<Meditation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "meditation" */
export type Meditation_Arr_Rel_Insert_Input = {
  data: Array<Meditation_Insert_Input>;
  on_conflict?: Maybe<Meditation_On_Conflict>;
};

/** aggregate avg on columns */
export type Meditation_Avg_Fields = {
  __typename?: 'meditation_avg_fields';
  id?: Maybe<Scalars['Float']>;
  period_id?: Maybe<Scalars['Float']>;
  repetitions?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "meditation" */
export type Meditation_Avg_Order_By = {
  id?: Maybe<Order_By>;
  period_id?: Maybe<Order_By>;
  repetitions?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "meditation". All fields are combined with a logical 'AND'. */
export type Meditation_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Meditation_Bool_Exp>>>;
  _not?: Maybe<Meditation_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Meditation_Bool_Exp>>>;
  activity_period?: Maybe<Activity_Period_Bool_Exp>;
  date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  meditation_type_id?: Maybe<Meditation_Type_Enum_Comparison_Exp>;
  period_id?: Maybe<Int_Comparison_Exp>;
  repetitions?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "meditation" */
export enum Meditation_Constraint {
  /** unique or primary key constraint */
  MeditationPkey = 'meditation_pkey'
}

/** input type for incrementing integer column in table "meditation" */
export type Meditation_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  period_id?: Maybe<Scalars['Int']>;
  repetitions?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "meditation" */
export type Meditation_Insert_Input = {
  activity_period?: Maybe<Activity_Period_Obj_Rel_Insert_Input>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  meditation_type_id?: Maybe<Meditation_Type_Enum>;
  period_id?: Maybe<Scalars['Int']>;
  repetitions?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Meditation_Max_Fields = {
  __typename?: 'meditation_max_fields';
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  period_id?: Maybe<Scalars['Int']>;
  repetitions?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "meditation" */
export type Meditation_Max_Order_By = {
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  period_id?: Maybe<Order_By>;
  repetitions?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Meditation_Min_Fields = {
  __typename?: 'meditation_min_fields';
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  period_id?: Maybe<Scalars['Int']>;
  repetitions?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "meditation" */
export type Meditation_Min_Order_By = {
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  period_id?: Maybe<Order_By>;
  repetitions?: Maybe<Order_By>;
};

/** response of any mutation on the table "meditation" */
export type Meditation_Mutation_Response = {
  __typename?: 'meditation_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Meditation>;
};

/** input type for inserting object relation for remote table "meditation" */
export type Meditation_Obj_Rel_Insert_Input = {
  data: Meditation_Insert_Input;
  on_conflict?: Maybe<Meditation_On_Conflict>;
};

/** on conflict condition type for table "meditation" */
export type Meditation_On_Conflict = {
  constraint: Meditation_Constraint;
  update_columns: Array<Meditation_Update_Column>;
  where?: Maybe<Meditation_Bool_Exp>;
};

/** ordering options when selecting data from "meditation" */
export type Meditation_Order_By = {
  activity_period?: Maybe<Activity_Period_Order_By>;
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meditation_type_id?: Maybe<Order_By>;
  period_id?: Maybe<Order_By>;
  repetitions?: Maybe<Order_By>;
};

/** primary key columns input for table: "meditation" */
export type Meditation_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "meditation" */
export enum Meditation_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  MeditationTypeId = 'meditation_type_id',
  /** column name */
  PeriodId = 'period_id',
  /** column name */
  Repetitions = 'repetitions'
}

/** input type for updating data in table "meditation" */
export type Meditation_Set_Input = {
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  meditation_type_id?: Maybe<Meditation_Type_Enum>;
  period_id?: Maybe<Scalars['Int']>;
  repetitions?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Meditation_Stddev_Fields = {
  __typename?: 'meditation_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  period_id?: Maybe<Scalars['Float']>;
  repetitions?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "meditation" */
export type Meditation_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  period_id?: Maybe<Order_By>;
  repetitions?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Meditation_Stddev_Pop_Fields = {
  __typename?: 'meditation_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  period_id?: Maybe<Scalars['Float']>;
  repetitions?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "meditation" */
export type Meditation_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  period_id?: Maybe<Order_By>;
  repetitions?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Meditation_Stddev_Samp_Fields = {
  __typename?: 'meditation_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  period_id?: Maybe<Scalars['Float']>;
  repetitions?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "meditation" */
export type Meditation_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  period_id?: Maybe<Order_By>;
  repetitions?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Meditation_Sum_Fields = {
  __typename?: 'meditation_sum_fields';
  id?: Maybe<Scalars['Int']>;
  period_id?: Maybe<Scalars['Int']>;
  repetitions?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "meditation" */
export type Meditation_Sum_Order_By = {
  id?: Maybe<Order_By>;
  period_id?: Maybe<Order_By>;
  repetitions?: Maybe<Order_By>;
};

/** columns and relationships of "meditation_type" */
export type Meditation_Type = {
  __typename?: 'meditation_type';
  value: Scalars['String'];
};

/** aggregated selection of "meditation_type" */
export type Meditation_Type_Aggregate = {
  __typename?: 'meditation_type_aggregate';
  aggregate?: Maybe<Meditation_Type_Aggregate_Fields>;
  nodes: Array<Meditation_Type>;
};

/** aggregate fields of "meditation_type" */
export type Meditation_Type_Aggregate_Fields = {
  __typename?: 'meditation_type_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Meditation_Type_Max_Fields>;
  min?: Maybe<Meditation_Type_Min_Fields>;
};


/** aggregate fields of "meditation_type" */
export type Meditation_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Meditation_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "meditation_type" */
export type Meditation_Type_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Meditation_Type_Max_Order_By>;
  min?: Maybe<Meditation_Type_Min_Order_By>;
};

/** input type for inserting array relation for remote table "meditation_type" */
export type Meditation_Type_Arr_Rel_Insert_Input = {
  data: Array<Meditation_Type_Insert_Input>;
  on_conflict?: Maybe<Meditation_Type_On_Conflict>;
};

/** Boolean expression to filter rows from the table "meditation_type". All fields are combined with a logical 'AND'. */
export type Meditation_Type_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Meditation_Type_Bool_Exp>>>;
  _not?: Maybe<Meditation_Type_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Meditation_Type_Bool_Exp>>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "meditation_type" */
export enum Meditation_Type_Constraint {
  /** unique or primary key constraint */
  MeditationTypePkey = 'meditation_type_pkey'
}

export enum Meditation_Type_Enum {
  DiamondMind = 'DIAMOND_MIND',
  GuruYoga = 'GURU_YOGA',
  Mandala = 'MANDALA',
  Prostrations = 'PROSTRATIONS',
  SixteenthKarmapa = 'SIXTEENTH_KARMAPA'
}

/** expression to compare columns of type meditation_type_enum. All fields are combined with logical 'AND'. */
export type Meditation_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Meditation_Type_Enum>;
  _in?: Maybe<Array<Meditation_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Meditation_Type_Enum>;
  _nin?: Maybe<Array<Meditation_Type_Enum>>;
};

/** input type for inserting data into table "meditation_type" */
export type Meditation_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Meditation_Type_Max_Fields = {
  __typename?: 'meditation_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "meditation_type" */
export type Meditation_Type_Max_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Meditation_Type_Min_Fields = {
  __typename?: 'meditation_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "meditation_type" */
export type Meditation_Type_Min_Order_By = {
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "meditation_type" */
export type Meditation_Type_Mutation_Response = {
  __typename?: 'meditation_type_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Meditation_Type>;
};

/** input type for inserting object relation for remote table "meditation_type" */
export type Meditation_Type_Obj_Rel_Insert_Input = {
  data: Meditation_Type_Insert_Input;
  on_conflict?: Maybe<Meditation_Type_On_Conflict>;
};

/** on conflict condition type for table "meditation_type" */
export type Meditation_Type_On_Conflict = {
  constraint: Meditation_Type_Constraint;
  update_columns: Array<Meditation_Type_Update_Column>;
  where?: Maybe<Meditation_Type_Bool_Exp>;
};

/** ordering options when selecting data from "meditation_type" */
export type Meditation_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "meditation_type" */
export type Meditation_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "meditation_type" */
export enum Meditation_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "meditation_type" */
export type Meditation_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "meditation_type" */
export enum Meditation_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** update columns of table "meditation" */
export enum Meditation_Update_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  MeditationTypeId = 'meditation_type_id',
  /** column name */
  PeriodId = 'period_id',
  /** column name */
  Repetitions = 'repetitions'
}

/** aggregate var_pop on columns */
export type Meditation_Var_Pop_Fields = {
  __typename?: 'meditation_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  period_id?: Maybe<Scalars['Float']>;
  repetitions?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "meditation" */
export type Meditation_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  period_id?: Maybe<Order_By>;
  repetitions?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Meditation_Var_Samp_Fields = {
  __typename?: 'meditation_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  period_id?: Maybe<Scalars['Float']>;
  repetitions?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "meditation" */
export type Meditation_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  period_id?: Maybe<Order_By>;
  repetitions?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Meditation_Variance_Fields = {
  __typename?: 'meditation_variance_fields';
  id?: Maybe<Scalars['Float']>;
  period_id?: Maybe<Scalars['Float']>;
  repetitions?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "meditation" */
export type Meditation_Variance_Order_By = {
  id?: Maybe<Order_By>;
  period_id?: Maybe<Order_By>;
  repetitions?: Maybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "activity_period" */
  delete_activity_period?: Maybe<Activity_Period_Mutation_Response>;
  /** delete single row from the table: "activity_period" */
  delete_activity_period_by_pk?: Maybe<Activity_Period>;
  /** delete data from the table: "contractor" */
  delete_contractor?: Maybe<Contractor_Mutation_Response>;
  /** delete single row from the table: "contractor" */
  delete_contractor_by_pk?: Maybe<Contractor>;
  /** delete data from the table: "meditation" */
  delete_meditation?: Maybe<Meditation_Mutation_Response>;
  /** delete single row from the table: "meditation" */
  delete_meditation_by_pk?: Maybe<Meditation>;
  /** delete data from the table: "meditation_type" */
  delete_meditation_type?: Maybe<Meditation_Type_Mutation_Response>;
  /** delete single row from the table: "meditation_type" */
  delete_meditation_type_by_pk?: Maybe<Meditation_Type>;
  /** delete data from the table: "period_projection" */
  delete_period_projection?: Maybe<Period_Projection_Mutation_Response>;
  /** delete single row from the table: "period_projection" */
  delete_period_projection_by_pk?: Maybe<Period_Projection>;
  /** delete data from the table: "period_type" */
  delete_period_type?: Maybe<Period_Type_Mutation_Response>;
  /** delete single row from the table: "period_type" */
  delete_period_type_by_pk?: Maybe<Period_Type>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "vehicle_offer" */
  delete_vehicle_offer?: Maybe<Vehicle_Offer_Mutation_Response>;
  /** delete single row from the table: "vehicle_offer" */
  delete_vehicle_offer_by_pk?: Maybe<Vehicle_Offer>;
  /** delete data from the table: "work_unit" */
  delete_work_unit?: Maybe<Work_Unit_Mutation_Response>;
  /** delete single row from the table: "work_unit" */
  delete_work_unit_by_pk?: Maybe<Work_Unit>;
  /** insert data into the table: "activity_period" */
  insert_activity_period?: Maybe<Activity_Period_Mutation_Response>;
  /** insert a single row into the table: "activity_period" */
  insert_activity_period_one?: Maybe<Activity_Period>;
  /** insert data into the table: "contractor" */
  insert_contractor?: Maybe<Contractor_Mutation_Response>;
  /** insert a single row into the table: "contractor" */
  insert_contractor_one?: Maybe<Contractor>;
  /** insert data into the table: "meditation" */
  insert_meditation?: Maybe<Meditation_Mutation_Response>;
  /** insert a single row into the table: "meditation" */
  insert_meditation_one?: Maybe<Meditation>;
  /** insert data into the table: "meditation_type" */
  insert_meditation_type?: Maybe<Meditation_Type_Mutation_Response>;
  /** insert a single row into the table: "meditation_type" */
  insert_meditation_type_one?: Maybe<Meditation_Type>;
  /** insert data into the table: "period_projection" */
  insert_period_projection?: Maybe<Period_Projection_Mutation_Response>;
  /** insert a single row into the table: "period_projection" */
  insert_period_projection_one?: Maybe<Period_Projection>;
  /** insert data into the table: "period_type" */
  insert_period_type?: Maybe<Period_Type_Mutation_Response>;
  /** insert a single row into the table: "period_type" */
  insert_period_type_one?: Maybe<Period_Type>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "vehicle_offer" */
  insert_vehicle_offer?: Maybe<Vehicle_Offer_Mutation_Response>;
  /** insert a single row into the table: "vehicle_offer" */
  insert_vehicle_offer_one?: Maybe<Vehicle_Offer>;
  /** insert data into the table: "work_unit" */
  insert_work_unit?: Maybe<Work_Unit_Mutation_Response>;
  /** insert a single row into the table: "work_unit" */
  insert_work_unit_one?: Maybe<Work_Unit>;
  /** update data of the table: "activity_period" */
  update_activity_period?: Maybe<Activity_Period_Mutation_Response>;
  /** update single row of the table: "activity_period" */
  update_activity_period_by_pk?: Maybe<Activity_Period>;
  /** update data of the table: "contractor" */
  update_contractor?: Maybe<Contractor_Mutation_Response>;
  /** update single row of the table: "contractor" */
  update_contractor_by_pk?: Maybe<Contractor>;
  /** update data of the table: "meditation" */
  update_meditation?: Maybe<Meditation_Mutation_Response>;
  /** update single row of the table: "meditation" */
  update_meditation_by_pk?: Maybe<Meditation>;
  /** update data of the table: "meditation_type" */
  update_meditation_type?: Maybe<Meditation_Type_Mutation_Response>;
  /** update single row of the table: "meditation_type" */
  update_meditation_type_by_pk?: Maybe<Meditation_Type>;
  /** update data of the table: "period_projection" */
  update_period_projection?: Maybe<Period_Projection_Mutation_Response>;
  /** update single row of the table: "period_projection" */
  update_period_projection_by_pk?: Maybe<Period_Projection>;
  /** update data of the table: "period_type" */
  update_period_type?: Maybe<Period_Type_Mutation_Response>;
  /** update single row of the table: "period_type" */
  update_period_type_by_pk?: Maybe<Period_Type>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update data of the table: "vehicle_offer" */
  update_vehicle_offer?: Maybe<Vehicle_Offer_Mutation_Response>;
  /** update single row of the table: "vehicle_offer" */
  update_vehicle_offer_by_pk?: Maybe<Vehicle_Offer>;
  /** update data of the table: "work_unit" */
  update_work_unit?: Maybe<Work_Unit_Mutation_Response>;
  /** update single row of the table: "work_unit" */
  update_work_unit_by_pk?: Maybe<Work_Unit>;
};


/** mutation root */
export type Mutation_RootDelete_Activity_PeriodArgs = {
  where: Activity_Period_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Activity_Period_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ContractorArgs = {
  where: Contractor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contractor_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_MeditationArgs = {
  where: Meditation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Meditation_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Meditation_TypeArgs = {
  where: Meditation_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Meditation_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Period_ProjectionArgs = {
  where: Period_Projection_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Period_Projection_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Period_TypeArgs = {
  where: Period_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Period_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Vehicle_OfferArgs = {
  where: Vehicle_Offer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vehicle_Offer_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Work_UnitArgs = {
  where: Work_Unit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Work_Unit_By_PkArgs = {
  activity_period_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsert_Activity_PeriodArgs = {
  objects: Array<Activity_Period_Insert_Input>;
  on_conflict?: Maybe<Activity_Period_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Activity_Period_OneArgs = {
  object: Activity_Period_Insert_Input;
  on_conflict?: Maybe<Activity_Period_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContractorArgs = {
  objects: Array<Contractor_Insert_Input>;
  on_conflict?: Maybe<Contractor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contractor_OneArgs = {
  object: Contractor_Insert_Input;
  on_conflict?: Maybe<Contractor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MeditationArgs = {
  objects: Array<Meditation_Insert_Input>;
  on_conflict?: Maybe<Meditation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Meditation_OneArgs = {
  object: Meditation_Insert_Input;
  on_conflict?: Maybe<Meditation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Meditation_TypeArgs = {
  objects: Array<Meditation_Type_Insert_Input>;
  on_conflict?: Maybe<Meditation_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Meditation_Type_OneArgs = {
  object: Meditation_Type_Insert_Input;
  on_conflict?: Maybe<Meditation_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Period_ProjectionArgs = {
  objects: Array<Period_Projection_Insert_Input>;
  on_conflict?: Maybe<Period_Projection_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Period_Projection_OneArgs = {
  object: Period_Projection_Insert_Input;
  on_conflict?: Maybe<Period_Projection_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Period_TypeArgs = {
  objects: Array<Period_Type_Insert_Input>;
  on_conflict?: Maybe<Period_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Period_Type_OneArgs = {
  object: Period_Type_Insert_Input;
  on_conflict?: Maybe<Period_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_OfferArgs = {
  objects: Array<Vehicle_Offer_Insert_Input>;
  on_conflict?: Maybe<Vehicle_Offer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_Offer_OneArgs = {
  object: Vehicle_Offer_Insert_Input;
  on_conflict?: Maybe<Vehicle_Offer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Work_UnitArgs = {
  objects: Array<Work_Unit_Insert_Input>;
  on_conflict?: Maybe<Work_Unit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Work_Unit_OneArgs = {
  object: Work_Unit_Insert_Input;
  on_conflict?: Maybe<Work_Unit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_Activity_PeriodArgs = {
  _inc?: Maybe<Activity_Period_Inc_Input>;
  _set?: Maybe<Activity_Period_Set_Input>;
  where: Activity_Period_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Activity_Period_By_PkArgs = {
  _inc?: Maybe<Activity_Period_Inc_Input>;
  _set?: Maybe<Activity_Period_Set_Input>;
  pk_columns: Activity_Period_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContractorArgs = {
  _inc?: Maybe<Contractor_Inc_Input>;
  _set?: Maybe<Contractor_Set_Input>;
  where: Contractor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contractor_By_PkArgs = {
  _inc?: Maybe<Contractor_Inc_Input>;
  _set?: Maybe<Contractor_Set_Input>;
  pk_columns: Contractor_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MeditationArgs = {
  _inc?: Maybe<Meditation_Inc_Input>;
  _set?: Maybe<Meditation_Set_Input>;
  where: Meditation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Meditation_By_PkArgs = {
  _inc?: Maybe<Meditation_Inc_Input>;
  _set?: Maybe<Meditation_Set_Input>;
  pk_columns: Meditation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Meditation_TypeArgs = {
  _set?: Maybe<Meditation_Type_Set_Input>;
  where: Meditation_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Meditation_Type_By_PkArgs = {
  _set?: Maybe<Meditation_Type_Set_Input>;
  pk_columns: Meditation_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Period_ProjectionArgs = {
  _inc?: Maybe<Period_Projection_Inc_Input>;
  _set?: Maybe<Period_Projection_Set_Input>;
  where: Period_Projection_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Period_Projection_By_PkArgs = {
  _inc?: Maybe<Period_Projection_Inc_Input>;
  _set?: Maybe<Period_Projection_Set_Input>;
  pk_columns: Period_Projection_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Period_TypeArgs = {
  _set?: Maybe<Period_Type_Set_Input>;
  where: Period_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Period_Type_By_PkArgs = {
  _set?: Maybe<Period_Type_Set_Input>;
  pk_columns: Period_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: Maybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: Maybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_OfferArgs = {
  _inc?: Maybe<Vehicle_Offer_Inc_Input>;
  _set?: Maybe<Vehicle_Offer_Set_Input>;
  where: Vehicle_Offer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Offer_By_PkArgs = {
  _inc?: Maybe<Vehicle_Offer_Inc_Input>;
  _set?: Maybe<Vehicle_Offer_Set_Input>;
  pk_columns: Vehicle_Offer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Work_UnitArgs = {
  _inc?: Maybe<Work_Unit_Inc_Input>;
  _set?: Maybe<Work_Unit_Set_Input>;
  where: Work_Unit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Work_Unit_By_PkArgs = {
  _inc?: Maybe<Work_Unit_Inc_Input>;
  _set?: Maybe<Work_Unit_Set_Input>;
  pk_columns: Work_Unit_Pk_Columns_Input;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "period_projection" */
export type Period_Projection = {
  __typename?: 'period_projection';
  activity_type_id: Period_Type_Enum;
  duration?: Maybe<Scalars['interval']>;
  end_date?: Maybe<Scalars['date']>;
  end_time?: Maybe<Scalars['timetz']>;
  id: Scalars['Int'];
  start_date?: Maybe<Scalars['date']>;
  start_time?: Maybe<Scalars['timetz']>;
};

/** aggregated selection of "period_projection" */
export type Period_Projection_Aggregate = {
  __typename?: 'period_projection_aggregate';
  aggregate?: Maybe<Period_Projection_Aggregate_Fields>;
  nodes: Array<Period_Projection>;
};

/** aggregate fields of "period_projection" */
export type Period_Projection_Aggregate_Fields = {
  __typename?: 'period_projection_aggregate_fields';
  avg?: Maybe<Period_Projection_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Period_Projection_Max_Fields>;
  min?: Maybe<Period_Projection_Min_Fields>;
  stddev?: Maybe<Period_Projection_Stddev_Fields>;
  stddev_pop?: Maybe<Period_Projection_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Period_Projection_Stddev_Samp_Fields>;
  sum?: Maybe<Period_Projection_Sum_Fields>;
  var_pop?: Maybe<Period_Projection_Var_Pop_Fields>;
  var_samp?: Maybe<Period_Projection_Var_Samp_Fields>;
  variance?: Maybe<Period_Projection_Variance_Fields>;
};


/** aggregate fields of "period_projection" */
export type Period_Projection_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Period_Projection_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "period_projection" */
export type Period_Projection_Aggregate_Order_By = {
  avg?: Maybe<Period_Projection_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Period_Projection_Max_Order_By>;
  min?: Maybe<Period_Projection_Min_Order_By>;
  stddev?: Maybe<Period_Projection_Stddev_Order_By>;
  stddev_pop?: Maybe<Period_Projection_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Period_Projection_Stddev_Samp_Order_By>;
  sum?: Maybe<Period_Projection_Sum_Order_By>;
  var_pop?: Maybe<Period_Projection_Var_Pop_Order_By>;
  var_samp?: Maybe<Period_Projection_Var_Samp_Order_By>;
  variance?: Maybe<Period_Projection_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "period_projection" */
export type Period_Projection_Arr_Rel_Insert_Input = {
  data: Array<Period_Projection_Insert_Input>;
  on_conflict?: Maybe<Period_Projection_On_Conflict>;
};

/** aggregate avg on columns */
export type Period_Projection_Avg_Fields = {
  __typename?: 'period_projection_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "period_projection" */
export type Period_Projection_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "period_projection". All fields are combined with a logical 'AND'. */
export type Period_Projection_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Period_Projection_Bool_Exp>>>;
  _not?: Maybe<Period_Projection_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Period_Projection_Bool_Exp>>>;
  activity_type_id?: Maybe<Period_Type_Enum_Comparison_Exp>;
  duration?: Maybe<Interval_Comparison_Exp>;
  end_date?: Maybe<Date_Comparison_Exp>;
  end_time?: Maybe<Timetz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  start_date?: Maybe<Date_Comparison_Exp>;
  start_time?: Maybe<Timetz_Comparison_Exp>;
};

/** unique or primary key constraints on table "period_projection" */
export enum Period_Projection_Constraint {
  /** unique or primary key constraint */
  PeriodProjectionPkey = 'period_projection_pkey'
}

/** input type for incrementing integer column in table "period_projection" */
export type Period_Projection_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "period_projection" */
export type Period_Projection_Insert_Input = {
  activity_type_id?: Maybe<Period_Type_Enum>;
  duration?: Maybe<Scalars['interval']>;
  end_date?: Maybe<Scalars['date']>;
  end_time?: Maybe<Scalars['timetz']>;
  id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['date']>;
  start_time?: Maybe<Scalars['timetz']>;
};

/** aggregate max on columns */
export type Period_Projection_Max_Fields = {
  __typename?: 'period_projection_max_fields';
  end_date?: Maybe<Scalars['date']>;
  end_time?: Maybe<Scalars['timetz']>;
  id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['date']>;
  start_time?: Maybe<Scalars['timetz']>;
};

/** order by max() on columns of table "period_projection" */
export type Period_Projection_Max_Order_By = {
  end_date?: Maybe<Order_By>;
  end_time?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  start_time?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Period_Projection_Min_Fields = {
  __typename?: 'period_projection_min_fields';
  end_date?: Maybe<Scalars['date']>;
  end_time?: Maybe<Scalars['timetz']>;
  id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['date']>;
  start_time?: Maybe<Scalars['timetz']>;
};

/** order by min() on columns of table "period_projection" */
export type Period_Projection_Min_Order_By = {
  end_date?: Maybe<Order_By>;
  end_time?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  start_time?: Maybe<Order_By>;
};

/** response of any mutation on the table "period_projection" */
export type Period_Projection_Mutation_Response = {
  __typename?: 'period_projection_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Period_Projection>;
};

/** input type for inserting object relation for remote table "period_projection" */
export type Period_Projection_Obj_Rel_Insert_Input = {
  data: Period_Projection_Insert_Input;
  on_conflict?: Maybe<Period_Projection_On_Conflict>;
};

/** on conflict condition type for table "period_projection" */
export type Period_Projection_On_Conflict = {
  constraint: Period_Projection_Constraint;
  update_columns: Array<Period_Projection_Update_Column>;
  where?: Maybe<Period_Projection_Bool_Exp>;
};

/** ordering options when selecting data from "period_projection" */
export type Period_Projection_Order_By = {
  activity_type_id?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  end_time?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  start_time?: Maybe<Order_By>;
};

/** primary key columns input for table: "period_projection" */
export type Period_Projection_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "period_projection" */
export enum Period_Projection_Select_Column {
  /** column name */
  ActivityTypeId = 'activity_type_id',
  /** column name */
  Duration = 'duration',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Id = 'id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  StartTime = 'start_time'
}

/** input type for updating data in table "period_projection" */
export type Period_Projection_Set_Input = {
  activity_type_id?: Maybe<Period_Type_Enum>;
  duration?: Maybe<Scalars['interval']>;
  end_date?: Maybe<Scalars['date']>;
  end_time?: Maybe<Scalars['timetz']>;
  id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['date']>;
  start_time?: Maybe<Scalars['timetz']>;
};

/** aggregate stddev on columns */
export type Period_Projection_Stddev_Fields = {
  __typename?: 'period_projection_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "period_projection" */
export type Period_Projection_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Period_Projection_Stddev_Pop_Fields = {
  __typename?: 'period_projection_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "period_projection" */
export type Period_Projection_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Period_Projection_Stddev_Samp_Fields = {
  __typename?: 'period_projection_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "period_projection" */
export type Period_Projection_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Period_Projection_Sum_Fields = {
  __typename?: 'period_projection_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "period_projection" */
export type Period_Projection_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "period_projection" */
export enum Period_Projection_Update_Column {
  /** column name */
  ActivityTypeId = 'activity_type_id',
  /** column name */
  Duration = 'duration',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Id = 'id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  StartTime = 'start_time'
}

/** aggregate var_pop on columns */
export type Period_Projection_Var_Pop_Fields = {
  __typename?: 'period_projection_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "period_projection" */
export type Period_Projection_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Period_Projection_Var_Samp_Fields = {
  __typename?: 'period_projection_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "period_projection" */
export type Period_Projection_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Period_Projection_Variance_Fields = {
  __typename?: 'period_projection_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "period_projection" */
export type Period_Projection_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "period_type" */
export type Period_Type = {
  __typename?: 'period_type';
  value: Scalars['String'];
};

/** aggregated selection of "period_type" */
export type Period_Type_Aggregate = {
  __typename?: 'period_type_aggregate';
  aggregate?: Maybe<Period_Type_Aggregate_Fields>;
  nodes: Array<Period_Type>;
};

/** aggregate fields of "period_type" */
export type Period_Type_Aggregate_Fields = {
  __typename?: 'period_type_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Period_Type_Max_Fields>;
  min?: Maybe<Period_Type_Min_Fields>;
};


/** aggregate fields of "period_type" */
export type Period_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Period_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "period_type" */
export type Period_Type_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Period_Type_Max_Order_By>;
  min?: Maybe<Period_Type_Min_Order_By>;
};

/** input type for inserting array relation for remote table "period_type" */
export type Period_Type_Arr_Rel_Insert_Input = {
  data: Array<Period_Type_Insert_Input>;
  on_conflict?: Maybe<Period_Type_On_Conflict>;
};

/** Boolean expression to filter rows from the table "period_type". All fields are combined with a logical 'AND'. */
export type Period_Type_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Period_Type_Bool_Exp>>>;
  _not?: Maybe<Period_Type_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Period_Type_Bool_Exp>>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "period_type" */
export enum Period_Type_Constraint {
  /** unique or primary key constraint */
  PeriodTypePkey = 'period_type_pkey'
}

export enum Period_Type_Enum {
  Meditation = 'MEDITATION',
  Qigong = 'QIGONG',
  Sleep = 'SLEEP',
  Walk = 'WALK',
  Work = 'WORK'
}

/** expression to compare columns of type period_type_enum. All fields are combined with logical 'AND'. */
export type Period_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Period_Type_Enum>;
  _in?: Maybe<Array<Period_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Period_Type_Enum>;
  _nin?: Maybe<Array<Period_Type_Enum>>;
};

/** input type for inserting data into table "period_type" */
export type Period_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Period_Type_Max_Fields = {
  __typename?: 'period_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "period_type" */
export type Period_Type_Max_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Period_Type_Min_Fields = {
  __typename?: 'period_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "period_type" */
export type Period_Type_Min_Order_By = {
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "period_type" */
export type Period_Type_Mutation_Response = {
  __typename?: 'period_type_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Period_Type>;
};

/** input type for inserting object relation for remote table "period_type" */
export type Period_Type_Obj_Rel_Insert_Input = {
  data: Period_Type_Insert_Input;
  on_conflict?: Maybe<Period_Type_On_Conflict>;
};

/** on conflict condition type for table "period_type" */
export type Period_Type_On_Conflict = {
  constraint: Period_Type_Constraint;
  update_columns: Array<Period_Type_Update_Column>;
  where?: Maybe<Period_Type_Bool_Exp>;
};

/** ordering options when selecting data from "period_type" */
export type Period_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "period_type" */
export type Period_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "period_type" */
export enum Period_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "period_type" */
export type Period_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "period_type" */
export enum Period_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "activity_period" */
  activity_period: Array<Activity_Period>;
  /** fetch aggregated fields from the table: "activity_period" */
  activity_period_aggregate: Activity_Period_Aggregate;
  /** fetch data from the table: "activity_period" using primary key columns */
  activity_period_by_pk?: Maybe<Activity_Period>;
  /** fetch data from the table: "contractor" */
  contractor: Array<Contractor>;
  /** fetch aggregated fields from the table: "contractor" */
  contractor_aggregate: Contractor_Aggregate;
  /** fetch data from the table: "contractor" using primary key columns */
  contractor_by_pk?: Maybe<Contractor>;
  /** fetch data from the table: "meditation" */
  meditation: Array<Meditation>;
  /** fetch aggregated fields from the table: "meditation" */
  meditation_aggregate: Meditation_Aggregate;
  /** fetch data from the table: "meditation" using primary key columns */
  meditation_by_pk?: Maybe<Meditation>;
  /** fetch data from the table: "meditation_type" */
  meditation_type: Array<Meditation_Type>;
  /** fetch aggregated fields from the table: "meditation_type" */
  meditation_type_aggregate: Meditation_Type_Aggregate;
  /** fetch data from the table: "meditation_type" using primary key columns */
  meditation_type_by_pk?: Maybe<Meditation_Type>;
  /** fetch data from the table: "period_projection" */
  period_projection: Array<Period_Projection>;
  /** fetch aggregated fields from the table: "period_projection" */
  period_projection_aggregate: Period_Projection_Aggregate;
  /** fetch data from the table: "period_projection" using primary key columns */
  period_projection_by_pk?: Maybe<Period_Projection>;
  /** fetch data from the table: "period_type" */
  period_type: Array<Period_Type>;
  /** fetch aggregated fields from the table: "period_type" */
  period_type_aggregate: Period_Type_Aggregate;
  /** fetch data from the table: "period_type" using primary key columns */
  period_type_by_pk?: Maybe<Period_Type>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "vehicle_offer" */
  vehicle_offer: Array<Vehicle_Offer>;
  /** fetch aggregated fields from the table: "vehicle_offer" */
  vehicle_offer_aggregate: Vehicle_Offer_Aggregate;
  /** fetch data from the table: "vehicle_offer" using primary key columns */
  vehicle_offer_by_pk?: Maybe<Vehicle_Offer>;
  /** fetch data from the table: "work_unit" */
  work_unit: Array<Work_Unit>;
  /** fetch aggregated fields from the table: "work_unit" */
  work_unit_aggregate: Work_Unit_Aggregate;
  /** fetch data from the table: "work_unit" using primary key columns */
  work_unit_by_pk?: Maybe<Work_Unit>;
};


/** query root */
export type Query_RootActivity_PeriodArgs = {
  distinct_on?: Maybe<Array<Activity_Period_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activity_Period_Order_By>>;
  where?: Maybe<Activity_Period_Bool_Exp>;
};


/** query root */
export type Query_RootActivity_Period_AggregateArgs = {
  distinct_on?: Maybe<Array<Activity_Period_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activity_Period_Order_By>>;
  where?: Maybe<Activity_Period_Bool_Exp>;
};


/** query root */
export type Query_RootActivity_Period_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootContractorArgs = {
  distinct_on?: Maybe<Array<Contractor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contractor_Order_By>>;
  where?: Maybe<Contractor_Bool_Exp>;
};


/** query root */
export type Query_RootContractor_AggregateArgs = {
  distinct_on?: Maybe<Array<Contractor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contractor_Order_By>>;
  where?: Maybe<Contractor_Bool_Exp>;
};


/** query root */
export type Query_RootContractor_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootMeditationArgs = {
  distinct_on?: Maybe<Array<Meditation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Meditation_Order_By>>;
  where?: Maybe<Meditation_Bool_Exp>;
};


/** query root */
export type Query_RootMeditation_AggregateArgs = {
  distinct_on?: Maybe<Array<Meditation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Meditation_Order_By>>;
  where?: Maybe<Meditation_Bool_Exp>;
};


/** query root */
export type Query_RootMeditation_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootMeditation_TypeArgs = {
  distinct_on?: Maybe<Array<Meditation_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Meditation_Type_Order_By>>;
  where?: Maybe<Meditation_Type_Bool_Exp>;
};


/** query root */
export type Query_RootMeditation_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Meditation_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Meditation_Type_Order_By>>;
  where?: Maybe<Meditation_Type_Bool_Exp>;
};


/** query root */
export type Query_RootMeditation_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type Query_RootPeriod_ProjectionArgs = {
  distinct_on?: Maybe<Array<Period_Projection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Period_Projection_Order_By>>;
  where?: Maybe<Period_Projection_Bool_Exp>;
};


/** query root */
export type Query_RootPeriod_Projection_AggregateArgs = {
  distinct_on?: Maybe<Array<Period_Projection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Period_Projection_Order_By>>;
  where?: Maybe<Period_Projection_Bool_Exp>;
};


/** query root */
export type Query_RootPeriod_Projection_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootPeriod_TypeArgs = {
  distinct_on?: Maybe<Array<Period_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Period_Type_Order_By>>;
  where?: Maybe<Period_Type_Bool_Exp>;
};


/** query root */
export type Query_RootPeriod_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Period_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Period_Type_Order_By>>;
  where?: Maybe<Period_Type_Bool_Exp>;
};


/** query root */
export type Query_RootPeriod_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type Query_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** query root */
export type Query_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** query root */
export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootVehicle_OfferArgs = {
  distinct_on?: Maybe<Array<Vehicle_Offer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Offer_Order_By>>;
  where?: Maybe<Vehicle_Offer_Bool_Exp>;
};


/** query root */
export type Query_RootVehicle_Offer_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Offer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Offer_Order_By>>;
  where?: Maybe<Vehicle_Offer_Bool_Exp>;
};


/** query root */
export type Query_RootVehicle_Offer_By_PkArgs = {
  id: Scalars['String'];
};


/** query root */
export type Query_RootWork_UnitArgs = {
  distinct_on?: Maybe<Array<Work_Unit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Work_Unit_Order_By>>;
  where?: Maybe<Work_Unit_Bool_Exp>;
};


/** query root */
export type Query_RootWork_Unit_AggregateArgs = {
  distinct_on?: Maybe<Array<Work_Unit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Work_Unit_Order_By>>;
  where?: Maybe<Work_Unit_Bool_Exp>;
};


/** query root */
export type Query_RootWork_Unit_By_PkArgs = {
  activity_period_id: Scalars['Int'];
};

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "activity_period" */
  activity_period: Array<Activity_Period>;
  /** fetch aggregated fields from the table: "activity_period" */
  activity_period_aggregate: Activity_Period_Aggregate;
  /** fetch data from the table: "activity_period" using primary key columns */
  activity_period_by_pk?: Maybe<Activity_Period>;
  /** fetch data from the table: "contractor" */
  contractor: Array<Contractor>;
  /** fetch aggregated fields from the table: "contractor" */
  contractor_aggregate: Contractor_Aggregate;
  /** fetch data from the table: "contractor" using primary key columns */
  contractor_by_pk?: Maybe<Contractor>;
  /** fetch data from the table: "meditation" */
  meditation: Array<Meditation>;
  /** fetch aggregated fields from the table: "meditation" */
  meditation_aggregate: Meditation_Aggregate;
  /** fetch data from the table: "meditation" using primary key columns */
  meditation_by_pk?: Maybe<Meditation>;
  /** fetch data from the table: "meditation_type" */
  meditation_type: Array<Meditation_Type>;
  /** fetch aggregated fields from the table: "meditation_type" */
  meditation_type_aggregate: Meditation_Type_Aggregate;
  /** fetch data from the table: "meditation_type" using primary key columns */
  meditation_type_by_pk?: Maybe<Meditation_Type>;
  /** fetch data from the table: "period_projection" */
  period_projection: Array<Period_Projection>;
  /** fetch aggregated fields from the table: "period_projection" */
  period_projection_aggregate: Period_Projection_Aggregate;
  /** fetch data from the table: "period_projection" using primary key columns */
  period_projection_by_pk?: Maybe<Period_Projection>;
  /** fetch data from the table: "period_type" */
  period_type: Array<Period_Type>;
  /** fetch aggregated fields from the table: "period_type" */
  period_type_aggregate: Period_Type_Aggregate;
  /** fetch data from the table: "period_type" using primary key columns */
  period_type_by_pk?: Maybe<Period_Type>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "vehicle_offer" */
  vehicle_offer: Array<Vehicle_Offer>;
  /** fetch aggregated fields from the table: "vehicle_offer" */
  vehicle_offer_aggregate: Vehicle_Offer_Aggregate;
  /** fetch data from the table: "vehicle_offer" using primary key columns */
  vehicle_offer_by_pk?: Maybe<Vehicle_Offer>;
  /** fetch data from the table: "work_unit" */
  work_unit: Array<Work_Unit>;
  /** fetch aggregated fields from the table: "work_unit" */
  work_unit_aggregate: Work_Unit_Aggregate;
  /** fetch data from the table: "work_unit" using primary key columns */
  work_unit_by_pk?: Maybe<Work_Unit>;
};


/** subscription root */
export type Subscription_RootActivity_PeriodArgs = {
  distinct_on?: Maybe<Array<Activity_Period_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activity_Period_Order_By>>;
  where?: Maybe<Activity_Period_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootActivity_Period_AggregateArgs = {
  distinct_on?: Maybe<Array<Activity_Period_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activity_Period_Order_By>>;
  where?: Maybe<Activity_Period_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootActivity_Period_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootContractorArgs = {
  distinct_on?: Maybe<Array<Contractor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contractor_Order_By>>;
  where?: Maybe<Contractor_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootContractor_AggregateArgs = {
  distinct_on?: Maybe<Array<Contractor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contractor_Order_By>>;
  where?: Maybe<Contractor_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootContractor_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootMeditationArgs = {
  distinct_on?: Maybe<Array<Meditation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Meditation_Order_By>>;
  where?: Maybe<Meditation_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMeditation_AggregateArgs = {
  distinct_on?: Maybe<Array<Meditation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Meditation_Order_By>>;
  where?: Maybe<Meditation_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMeditation_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootMeditation_TypeArgs = {
  distinct_on?: Maybe<Array<Meditation_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Meditation_Type_Order_By>>;
  where?: Maybe<Meditation_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMeditation_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Meditation_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Meditation_Type_Order_By>>;
  where?: Maybe<Meditation_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMeditation_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type Subscription_RootPeriod_ProjectionArgs = {
  distinct_on?: Maybe<Array<Period_Projection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Period_Projection_Order_By>>;
  where?: Maybe<Period_Projection_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPeriod_Projection_AggregateArgs = {
  distinct_on?: Maybe<Array<Period_Projection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Period_Projection_Order_By>>;
  where?: Maybe<Period_Projection_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPeriod_Projection_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootPeriod_TypeArgs = {
  distinct_on?: Maybe<Array<Period_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Period_Type_Order_By>>;
  where?: Maybe<Period_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPeriod_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Period_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Period_Type_Order_By>>;
  where?: Maybe<Period_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPeriod_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type Subscription_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootVehicle_OfferArgs = {
  distinct_on?: Maybe<Array<Vehicle_Offer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Offer_Order_By>>;
  where?: Maybe<Vehicle_Offer_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootVehicle_Offer_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Offer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Offer_Order_By>>;
  where?: Maybe<Vehicle_Offer_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootVehicle_Offer_By_PkArgs = {
  id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootWork_UnitArgs = {
  distinct_on?: Maybe<Array<Work_Unit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Work_Unit_Order_By>>;
  where?: Maybe<Work_Unit_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootWork_Unit_AggregateArgs = {
  distinct_on?: Maybe<Array<Work_Unit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Work_Unit_Order_By>>;
  where?: Maybe<Work_Unit_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootWork_Unit_By_PkArgs = {
  activity_period_id: Scalars['Int'];
};


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};


/** expression to compare columns of type timetz. All fields are combined with logical 'AND'. */
export type Timetz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timetz']>;
  _gt?: Maybe<Scalars['timetz']>;
  _gte?: Maybe<Scalars['timetz']>;
  _in?: Maybe<Array<Scalars['timetz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timetz']>;
  _lte?: Maybe<Scalars['timetz']>;
  _neq?: Maybe<Scalars['timetz']>;
  _nin?: Maybe<Array<Scalars['timetz']>>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  email: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Max_Order_By>;
  min?: Maybe<User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Bool_Exp>>>;
  _not?: Maybe<User_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Bool_Exp>>>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint */
  UserPkey = 'user_pkey'
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** on conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns: Array<User_Update_Column>;
  where?: Maybe<User_Bool_Exp>;
};

/** ordering options when selecting data from "user" */
export type User_Order_By = {
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "user" */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}


/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "vehicle_offer" */
export type Vehicle_Offer = {
  __typename?: 'vehicle_offer';
  VIN?: Maybe<Scalars['String']>;
  accident_free?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  country_of_origin?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  door_count?: Maybe<Scalars['Int']>;
  drive?: Maybe<Scalars['String']>;
  engine_displacement?: Maybe<Scalars['Int']>;
  engine_power?: Maybe<Scalars['Int']>;
  fuel_type?: Maybe<Scalars['String']>;
  generation?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  milage?: Maybe<Scalars['Int']>;
  negotiable: Scalars['Boolean'];
  offer_date?: Maybe<Scalars['timestamptz']>;
  price?: Maybe<Scalars['Int']>;
  production_year?: Maybe<Scalars['Int']>;
  registered_in_poland?: Maybe<Scalars['Boolean']>;
  salesman_type?: Maybe<Scalars['String']>;
  transmission?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  used?: Maybe<Scalars['Boolean']>;
  vat_invoice: Scalars['Boolean'];
};

/** aggregated selection of "vehicle_offer" */
export type Vehicle_Offer_Aggregate = {
  __typename?: 'vehicle_offer_aggregate';
  aggregate?: Maybe<Vehicle_Offer_Aggregate_Fields>;
  nodes: Array<Vehicle_Offer>;
};

/** aggregate fields of "vehicle_offer" */
export type Vehicle_Offer_Aggregate_Fields = {
  __typename?: 'vehicle_offer_aggregate_fields';
  avg?: Maybe<Vehicle_Offer_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Vehicle_Offer_Max_Fields>;
  min?: Maybe<Vehicle_Offer_Min_Fields>;
  stddev?: Maybe<Vehicle_Offer_Stddev_Fields>;
  stddev_pop?: Maybe<Vehicle_Offer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vehicle_Offer_Stddev_Samp_Fields>;
  sum?: Maybe<Vehicle_Offer_Sum_Fields>;
  var_pop?: Maybe<Vehicle_Offer_Var_Pop_Fields>;
  var_samp?: Maybe<Vehicle_Offer_Var_Samp_Fields>;
  variance?: Maybe<Vehicle_Offer_Variance_Fields>;
};


/** aggregate fields of "vehicle_offer" */
export type Vehicle_Offer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vehicle_Offer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vehicle_offer" */
export type Vehicle_Offer_Aggregate_Order_By = {
  avg?: Maybe<Vehicle_Offer_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Vehicle_Offer_Max_Order_By>;
  min?: Maybe<Vehicle_Offer_Min_Order_By>;
  stddev?: Maybe<Vehicle_Offer_Stddev_Order_By>;
  stddev_pop?: Maybe<Vehicle_Offer_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Vehicle_Offer_Stddev_Samp_Order_By>;
  sum?: Maybe<Vehicle_Offer_Sum_Order_By>;
  var_pop?: Maybe<Vehicle_Offer_Var_Pop_Order_By>;
  var_samp?: Maybe<Vehicle_Offer_Var_Samp_Order_By>;
  variance?: Maybe<Vehicle_Offer_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "vehicle_offer" */
export type Vehicle_Offer_Arr_Rel_Insert_Input = {
  data: Array<Vehicle_Offer_Insert_Input>;
  on_conflict?: Maybe<Vehicle_Offer_On_Conflict>;
};

/** aggregate avg on columns */
export type Vehicle_Offer_Avg_Fields = {
  __typename?: 'vehicle_offer_avg_fields';
  door_count?: Maybe<Scalars['Float']>;
  engine_displacement?: Maybe<Scalars['Float']>;
  engine_power?: Maybe<Scalars['Float']>;
  milage?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  production_year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vehicle_offer" */
export type Vehicle_Offer_Avg_Order_By = {
  door_count?: Maybe<Order_By>;
  engine_displacement?: Maybe<Order_By>;
  engine_power?: Maybe<Order_By>;
  milage?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vehicle_offer". All fields are combined with a logical 'AND'. */
export type Vehicle_Offer_Bool_Exp = {
  VIN?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Vehicle_Offer_Bool_Exp>>>;
  _not?: Maybe<Vehicle_Offer_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Vehicle_Offer_Bool_Exp>>>;
  accident_free?: Maybe<Boolean_Comparison_Exp>;
  address?: Maybe<String_Comparison_Exp>;
  color?: Maybe<String_Comparison_Exp>;
  country_of_origin?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  door_count?: Maybe<Int_Comparison_Exp>;
  drive?: Maybe<String_Comparison_Exp>;
  engine_displacement?: Maybe<Int_Comparison_Exp>;
  engine_power?: Maybe<Int_Comparison_Exp>;
  fuel_type?: Maybe<String_Comparison_Exp>;
  generation?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  milage?: Maybe<Int_Comparison_Exp>;
  negotiable?: Maybe<Boolean_Comparison_Exp>;
  offer_date?: Maybe<Timestamptz_Comparison_Exp>;
  price?: Maybe<Int_Comparison_Exp>;
  production_year?: Maybe<Int_Comparison_Exp>;
  registered_in_poland?: Maybe<Boolean_Comparison_Exp>;
  salesman_type?: Maybe<String_Comparison_Exp>;
  transmission?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  used?: Maybe<Boolean_Comparison_Exp>;
  vat_invoice?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "vehicle_offer" */
export enum Vehicle_Offer_Constraint {
  /** unique or primary key constraint */
  VehicleOfferPkey = 'vehicle_offer_pkey'
}

/** input type for incrementing integer column in table "vehicle_offer" */
export type Vehicle_Offer_Inc_Input = {
  door_count?: Maybe<Scalars['Int']>;
  engine_displacement?: Maybe<Scalars['Int']>;
  engine_power?: Maybe<Scalars['Int']>;
  milage?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  production_year?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vehicle_offer" */
export type Vehicle_Offer_Insert_Input = {
  VIN?: Maybe<Scalars['String']>;
  accident_free?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  country_of_origin?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  door_count?: Maybe<Scalars['Int']>;
  drive?: Maybe<Scalars['String']>;
  engine_displacement?: Maybe<Scalars['Int']>;
  engine_power?: Maybe<Scalars['Int']>;
  fuel_type?: Maybe<Scalars['String']>;
  generation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  milage?: Maybe<Scalars['Int']>;
  negotiable?: Maybe<Scalars['Boolean']>;
  offer_date?: Maybe<Scalars['timestamptz']>;
  price?: Maybe<Scalars['Int']>;
  production_year?: Maybe<Scalars['Int']>;
  registered_in_poland?: Maybe<Scalars['Boolean']>;
  salesman_type?: Maybe<Scalars['String']>;
  transmission?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  used?: Maybe<Scalars['Boolean']>;
  vat_invoice?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Vehicle_Offer_Max_Fields = {
  __typename?: 'vehicle_offer_max_fields';
  VIN?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  country_of_origin?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  door_count?: Maybe<Scalars['Int']>;
  drive?: Maybe<Scalars['String']>;
  engine_displacement?: Maybe<Scalars['Int']>;
  engine_power?: Maybe<Scalars['Int']>;
  fuel_type?: Maybe<Scalars['String']>;
  generation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  milage?: Maybe<Scalars['Int']>;
  offer_date?: Maybe<Scalars['timestamptz']>;
  price?: Maybe<Scalars['Int']>;
  production_year?: Maybe<Scalars['Int']>;
  salesman_type?: Maybe<Scalars['String']>;
  transmission?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "vehicle_offer" */
export type Vehicle_Offer_Max_Order_By = {
  VIN?: Maybe<Order_By>;
  address?: Maybe<Order_By>;
  color?: Maybe<Order_By>;
  country_of_origin?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  door_count?: Maybe<Order_By>;
  drive?: Maybe<Order_By>;
  engine_displacement?: Maybe<Order_By>;
  engine_power?: Maybe<Order_By>;
  fuel_type?: Maybe<Order_By>;
  generation?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  milage?: Maybe<Order_By>;
  offer_date?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
  salesman_type?: Maybe<Order_By>;
  transmission?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Vehicle_Offer_Min_Fields = {
  __typename?: 'vehicle_offer_min_fields';
  VIN?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  country_of_origin?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  door_count?: Maybe<Scalars['Int']>;
  drive?: Maybe<Scalars['String']>;
  engine_displacement?: Maybe<Scalars['Int']>;
  engine_power?: Maybe<Scalars['Int']>;
  fuel_type?: Maybe<Scalars['String']>;
  generation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  milage?: Maybe<Scalars['Int']>;
  offer_date?: Maybe<Scalars['timestamptz']>;
  price?: Maybe<Scalars['Int']>;
  production_year?: Maybe<Scalars['Int']>;
  salesman_type?: Maybe<Scalars['String']>;
  transmission?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "vehicle_offer" */
export type Vehicle_Offer_Min_Order_By = {
  VIN?: Maybe<Order_By>;
  address?: Maybe<Order_By>;
  color?: Maybe<Order_By>;
  country_of_origin?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  door_count?: Maybe<Order_By>;
  drive?: Maybe<Order_By>;
  engine_displacement?: Maybe<Order_By>;
  engine_power?: Maybe<Order_By>;
  fuel_type?: Maybe<Order_By>;
  generation?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  milage?: Maybe<Order_By>;
  offer_date?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
  salesman_type?: Maybe<Order_By>;
  transmission?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "vehicle_offer" */
export type Vehicle_Offer_Mutation_Response = {
  __typename?: 'vehicle_offer_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Vehicle_Offer>;
};

/** input type for inserting object relation for remote table "vehicle_offer" */
export type Vehicle_Offer_Obj_Rel_Insert_Input = {
  data: Vehicle_Offer_Insert_Input;
  on_conflict?: Maybe<Vehicle_Offer_On_Conflict>;
};

/** on conflict condition type for table "vehicle_offer" */
export type Vehicle_Offer_On_Conflict = {
  constraint: Vehicle_Offer_Constraint;
  update_columns: Array<Vehicle_Offer_Update_Column>;
  where?: Maybe<Vehicle_Offer_Bool_Exp>;
};

/** ordering options when selecting data from "vehicle_offer" */
export type Vehicle_Offer_Order_By = {
  VIN?: Maybe<Order_By>;
  accident_free?: Maybe<Order_By>;
  address?: Maybe<Order_By>;
  color?: Maybe<Order_By>;
  country_of_origin?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  door_count?: Maybe<Order_By>;
  drive?: Maybe<Order_By>;
  engine_displacement?: Maybe<Order_By>;
  engine_power?: Maybe<Order_By>;
  fuel_type?: Maybe<Order_By>;
  generation?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  milage?: Maybe<Order_By>;
  negotiable?: Maybe<Order_By>;
  offer_date?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
  registered_in_poland?: Maybe<Order_By>;
  salesman_type?: Maybe<Order_By>;
  transmission?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  used?: Maybe<Order_By>;
  vat_invoice?: Maybe<Order_By>;
};

/** primary key columns input for table: "vehicle_offer" */
export type Vehicle_Offer_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "vehicle_offer" */
export enum Vehicle_Offer_Select_Column {
  /** column name */
  Vin = 'VIN',
  /** column name */
  AccidentFree = 'accident_free',
  /** column name */
  Address = 'address',
  /** column name */
  Color = 'color',
  /** column name */
  CountryOfOrigin = 'country_of_origin',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoorCount = 'door_count',
  /** column name */
  Drive = 'drive',
  /** column name */
  EngineDisplacement = 'engine_displacement',
  /** column name */
  EnginePower = 'engine_power',
  /** column name */
  FuelType = 'fuel_type',
  /** column name */
  Generation = 'generation',
  /** column name */
  Id = 'id',
  /** column name */
  Milage = 'milage',
  /** column name */
  Negotiable = 'negotiable',
  /** column name */
  OfferDate = 'offer_date',
  /** column name */
  Price = 'price',
  /** column name */
  ProductionYear = 'production_year',
  /** column name */
  RegisteredInPoland = 'registered_in_poland',
  /** column name */
  SalesmanType = 'salesman_type',
  /** column name */
  Transmission = 'transmission',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Used = 'used',
  /** column name */
  VatInvoice = 'vat_invoice'
}

/** input type for updating data in table "vehicle_offer" */
export type Vehicle_Offer_Set_Input = {
  VIN?: Maybe<Scalars['String']>;
  accident_free?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  country_of_origin?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  door_count?: Maybe<Scalars['Int']>;
  drive?: Maybe<Scalars['String']>;
  engine_displacement?: Maybe<Scalars['Int']>;
  engine_power?: Maybe<Scalars['Int']>;
  fuel_type?: Maybe<Scalars['String']>;
  generation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  milage?: Maybe<Scalars['Int']>;
  negotiable?: Maybe<Scalars['Boolean']>;
  offer_date?: Maybe<Scalars['timestamptz']>;
  price?: Maybe<Scalars['Int']>;
  production_year?: Maybe<Scalars['Int']>;
  registered_in_poland?: Maybe<Scalars['Boolean']>;
  salesman_type?: Maybe<Scalars['String']>;
  transmission?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  used?: Maybe<Scalars['Boolean']>;
  vat_invoice?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Vehicle_Offer_Stddev_Fields = {
  __typename?: 'vehicle_offer_stddev_fields';
  door_count?: Maybe<Scalars['Float']>;
  engine_displacement?: Maybe<Scalars['Float']>;
  engine_power?: Maybe<Scalars['Float']>;
  milage?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  production_year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vehicle_offer" */
export type Vehicle_Offer_Stddev_Order_By = {
  door_count?: Maybe<Order_By>;
  engine_displacement?: Maybe<Order_By>;
  engine_power?: Maybe<Order_By>;
  milage?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Vehicle_Offer_Stddev_Pop_Fields = {
  __typename?: 'vehicle_offer_stddev_pop_fields';
  door_count?: Maybe<Scalars['Float']>;
  engine_displacement?: Maybe<Scalars['Float']>;
  engine_power?: Maybe<Scalars['Float']>;
  milage?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  production_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vehicle_offer" */
export type Vehicle_Offer_Stddev_Pop_Order_By = {
  door_count?: Maybe<Order_By>;
  engine_displacement?: Maybe<Order_By>;
  engine_power?: Maybe<Order_By>;
  milage?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Vehicle_Offer_Stddev_Samp_Fields = {
  __typename?: 'vehicle_offer_stddev_samp_fields';
  door_count?: Maybe<Scalars['Float']>;
  engine_displacement?: Maybe<Scalars['Float']>;
  engine_power?: Maybe<Scalars['Float']>;
  milage?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  production_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vehicle_offer" */
export type Vehicle_Offer_Stddev_Samp_Order_By = {
  door_count?: Maybe<Order_By>;
  engine_displacement?: Maybe<Order_By>;
  engine_power?: Maybe<Order_By>;
  milage?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Vehicle_Offer_Sum_Fields = {
  __typename?: 'vehicle_offer_sum_fields';
  door_count?: Maybe<Scalars['Int']>;
  engine_displacement?: Maybe<Scalars['Int']>;
  engine_power?: Maybe<Scalars['Int']>;
  milage?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  production_year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vehicle_offer" */
export type Vehicle_Offer_Sum_Order_By = {
  door_count?: Maybe<Order_By>;
  engine_displacement?: Maybe<Order_By>;
  engine_power?: Maybe<Order_By>;
  milage?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
};

/** update columns of table "vehicle_offer" */
export enum Vehicle_Offer_Update_Column {
  /** column name */
  Vin = 'VIN',
  /** column name */
  AccidentFree = 'accident_free',
  /** column name */
  Address = 'address',
  /** column name */
  Color = 'color',
  /** column name */
  CountryOfOrigin = 'country_of_origin',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoorCount = 'door_count',
  /** column name */
  Drive = 'drive',
  /** column name */
  EngineDisplacement = 'engine_displacement',
  /** column name */
  EnginePower = 'engine_power',
  /** column name */
  FuelType = 'fuel_type',
  /** column name */
  Generation = 'generation',
  /** column name */
  Id = 'id',
  /** column name */
  Milage = 'milage',
  /** column name */
  Negotiable = 'negotiable',
  /** column name */
  OfferDate = 'offer_date',
  /** column name */
  Price = 'price',
  /** column name */
  ProductionYear = 'production_year',
  /** column name */
  RegisteredInPoland = 'registered_in_poland',
  /** column name */
  SalesmanType = 'salesman_type',
  /** column name */
  Transmission = 'transmission',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Used = 'used',
  /** column name */
  VatInvoice = 'vat_invoice'
}

/** aggregate var_pop on columns */
export type Vehicle_Offer_Var_Pop_Fields = {
  __typename?: 'vehicle_offer_var_pop_fields';
  door_count?: Maybe<Scalars['Float']>;
  engine_displacement?: Maybe<Scalars['Float']>;
  engine_power?: Maybe<Scalars['Float']>;
  milage?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  production_year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vehicle_offer" */
export type Vehicle_Offer_Var_Pop_Order_By = {
  door_count?: Maybe<Order_By>;
  engine_displacement?: Maybe<Order_By>;
  engine_power?: Maybe<Order_By>;
  milage?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Vehicle_Offer_Var_Samp_Fields = {
  __typename?: 'vehicle_offer_var_samp_fields';
  door_count?: Maybe<Scalars['Float']>;
  engine_displacement?: Maybe<Scalars['Float']>;
  engine_power?: Maybe<Scalars['Float']>;
  milage?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  production_year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vehicle_offer" */
export type Vehicle_Offer_Var_Samp_Order_By = {
  door_count?: Maybe<Order_By>;
  engine_displacement?: Maybe<Order_By>;
  engine_power?: Maybe<Order_By>;
  milage?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Vehicle_Offer_Variance_Fields = {
  __typename?: 'vehicle_offer_variance_fields';
  door_count?: Maybe<Scalars['Float']>;
  engine_displacement?: Maybe<Scalars['Float']>;
  engine_power?: Maybe<Scalars['Float']>;
  milage?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  production_year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vehicle_offer" */
export type Vehicle_Offer_Variance_Order_By = {
  door_count?: Maybe<Order_By>;
  engine_displacement?: Maybe<Order_By>;
  engine_power?: Maybe<Order_By>;
  milage?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
};

/** columns and relationships of "work_unit" */
export type Work_Unit = {
  __typename?: 'work_unit';
  /** An object relationship */
  activity_period: Activity_Period;
  activity_period_id: Scalars['Int'];
  /** An object relationship */
  contractor: Contractor;
  contractor_id: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
};

/** aggregated selection of "work_unit" */
export type Work_Unit_Aggregate = {
  __typename?: 'work_unit_aggregate';
  aggregate?: Maybe<Work_Unit_Aggregate_Fields>;
  nodes: Array<Work_Unit>;
};

/** aggregate fields of "work_unit" */
export type Work_Unit_Aggregate_Fields = {
  __typename?: 'work_unit_aggregate_fields';
  avg?: Maybe<Work_Unit_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Work_Unit_Max_Fields>;
  min?: Maybe<Work_Unit_Min_Fields>;
  stddev?: Maybe<Work_Unit_Stddev_Fields>;
  stddev_pop?: Maybe<Work_Unit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Work_Unit_Stddev_Samp_Fields>;
  sum?: Maybe<Work_Unit_Sum_Fields>;
  var_pop?: Maybe<Work_Unit_Var_Pop_Fields>;
  var_samp?: Maybe<Work_Unit_Var_Samp_Fields>;
  variance?: Maybe<Work_Unit_Variance_Fields>;
};


/** aggregate fields of "work_unit" */
export type Work_Unit_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Work_Unit_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "work_unit" */
export type Work_Unit_Aggregate_Order_By = {
  avg?: Maybe<Work_Unit_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Work_Unit_Max_Order_By>;
  min?: Maybe<Work_Unit_Min_Order_By>;
  stddev?: Maybe<Work_Unit_Stddev_Order_By>;
  stddev_pop?: Maybe<Work_Unit_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Work_Unit_Stddev_Samp_Order_By>;
  sum?: Maybe<Work_Unit_Sum_Order_By>;
  var_pop?: Maybe<Work_Unit_Var_Pop_Order_By>;
  var_samp?: Maybe<Work_Unit_Var_Samp_Order_By>;
  variance?: Maybe<Work_Unit_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "work_unit" */
export type Work_Unit_Arr_Rel_Insert_Input = {
  data: Array<Work_Unit_Insert_Input>;
  on_conflict?: Maybe<Work_Unit_On_Conflict>;
};

/** aggregate avg on columns */
export type Work_Unit_Avg_Fields = {
  __typename?: 'work_unit_avg_fields';
  activity_period_id?: Maybe<Scalars['Float']>;
  contractor_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "work_unit" */
export type Work_Unit_Avg_Order_By = {
  activity_period_id?: Maybe<Order_By>;
  contractor_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "work_unit". All fields are combined with a logical 'AND'. */
export type Work_Unit_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Work_Unit_Bool_Exp>>>;
  _not?: Maybe<Work_Unit_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Work_Unit_Bool_Exp>>>;
  activity_period?: Maybe<Activity_Period_Bool_Exp>;
  activity_period_id?: Maybe<Int_Comparison_Exp>;
  contractor?: Maybe<Contractor_Bool_Exp>;
  contractor_id?: Maybe<Int_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "work_unit" */
export enum Work_Unit_Constraint {
  /** unique or primary key constraint */
  WorkUnitPkey = 'work_unit_pkey'
}

/** input type for incrementing integer column in table "work_unit" */
export type Work_Unit_Inc_Input = {
  activity_period_id?: Maybe<Scalars['Int']>;
  contractor_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "work_unit" */
export type Work_Unit_Insert_Input = {
  activity_period?: Maybe<Activity_Period_Obj_Rel_Insert_Input>;
  activity_period_id?: Maybe<Scalars['Int']>;
  contractor?: Maybe<Contractor_Obj_Rel_Insert_Input>;
  contractor_id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Work_Unit_Max_Fields = {
  __typename?: 'work_unit_max_fields';
  activity_period_id?: Maybe<Scalars['Int']>;
  contractor_id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "work_unit" */
export type Work_Unit_Max_Order_By = {
  activity_period_id?: Maybe<Order_By>;
  contractor_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Work_Unit_Min_Fields = {
  __typename?: 'work_unit_min_fields';
  activity_period_id?: Maybe<Scalars['Int']>;
  contractor_id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "work_unit" */
export type Work_Unit_Min_Order_By = {
  activity_period_id?: Maybe<Order_By>;
  contractor_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
};

/** response of any mutation on the table "work_unit" */
export type Work_Unit_Mutation_Response = {
  __typename?: 'work_unit_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Work_Unit>;
};

/** input type for inserting object relation for remote table "work_unit" */
export type Work_Unit_Obj_Rel_Insert_Input = {
  data: Work_Unit_Insert_Input;
  on_conflict?: Maybe<Work_Unit_On_Conflict>;
};

/** on conflict condition type for table "work_unit" */
export type Work_Unit_On_Conflict = {
  constraint: Work_Unit_Constraint;
  update_columns: Array<Work_Unit_Update_Column>;
  where?: Maybe<Work_Unit_Bool_Exp>;
};

/** ordering options when selecting data from "work_unit" */
export type Work_Unit_Order_By = {
  activity_period?: Maybe<Activity_Period_Order_By>;
  activity_period_id?: Maybe<Order_By>;
  contractor?: Maybe<Contractor_Order_By>;
  contractor_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
};

/** primary key columns input for table: "work_unit" */
export type Work_Unit_Pk_Columns_Input = {
  activity_period_id: Scalars['Int'];
};

/** select columns of table "work_unit" */
export enum Work_Unit_Select_Column {
  /** column name */
  ActivityPeriodId = 'activity_period_id',
  /** column name */
  ContractorId = 'contractor_id',
  /** column name */
  Description = 'description'
}

/** input type for updating data in table "work_unit" */
export type Work_Unit_Set_Input = {
  activity_period_id?: Maybe<Scalars['Int']>;
  contractor_id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Work_Unit_Stddev_Fields = {
  __typename?: 'work_unit_stddev_fields';
  activity_period_id?: Maybe<Scalars['Float']>;
  contractor_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "work_unit" */
export type Work_Unit_Stddev_Order_By = {
  activity_period_id?: Maybe<Order_By>;
  contractor_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Work_Unit_Stddev_Pop_Fields = {
  __typename?: 'work_unit_stddev_pop_fields';
  activity_period_id?: Maybe<Scalars['Float']>;
  contractor_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "work_unit" */
export type Work_Unit_Stddev_Pop_Order_By = {
  activity_period_id?: Maybe<Order_By>;
  contractor_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Work_Unit_Stddev_Samp_Fields = {
  __typename?: 'work_unit_stddev_samp_fields';
  activity_period_id?: Maybe<Scalars['Float']>;
  contractor_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "work_unit" */
export type Work_Unit_Stddev_Samp_Order_By = {
  activity_period_id?: Maybe<Order_By>;
  contractor_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Work_Unit_Sum_Fields = {
  __typename?: 'work_unit_sum_fields';
  activity_period_id?: Maybe<Scalars['Int']>;
  contractor_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "work_unit" */
export type Work_Unit_Sum_Order_By = {
  activity_period_id?: Maybe<Order_By>;
  contractor_id?: Maybe<Order_By>;
};

/** update columns of table "work_unit" */
export enum Work_Unit_Update_Column {
  /** column name */
  ActivityPeriodId = 'activity_period_id',
  /** column name */
  ContractorId = 'contractor_id',
  /** column name */
  Description = 'description'
}

/** aggregate var_pop on columns */
export type Work_Unit_Var_Pop_Fields = {
  __typename?: 'work_unit_var_pop_fields';
  activity_period_id?: Maybe<Scalars['Float']>;
  contractor_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "work_unit" */
export type Work_Unit_Var_Pop_Order_By = {
  activity_period_id?: Maybe<Order_By>;
  contractor_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Work_Unit_Var_Samp_Fields = {
  __typename?: 'work_unit_var_samp_fields';
  activity_period_id?: Maybe<Scalars['Float']>;
  contractor_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "work_unit" */
export type Work_Unit_Var_Samp_Order_By = {
  activity_period_id?: Maybe<Order_By>;
  contractor_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Work_Unit_Variance_Fields = {
  __typename?: 'work_unit_variance_fields';
  activity_period_id?: Maybe<Scalars['Float']>;
  contractor_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "work_unit" */
export type Work_Unit_Variance_Order_By = {
  activity_period_id?: Maybe<Order_By>;
  contractor_id?: Maybe<Order_By>;
};

export type CurrentActivitiesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type CurrentActivitiesSubscription = (
  { __typename?: 'subscription_root' }
  & { activity_period: Array<(
    { __typename?: 'activity_period' }
    & Pick<Activity_Period, 'id' | 'start_time' | 'activity_type_id'>
  )> }
);

export type ActivityHistorySubscriptionVariables = Exact<{
  activity?: Maybe<Period_Type_Enum>;
}>;


export type ActivityHistorySubscription = (
  { __typename?: 'subscription_root' }
  & { activity_period: Array<(
    { __typename?: 'activity_period' }
    & Pick<Activity_Period, 'id' | 'start_time' | 'end_time'>
  )> }
);

export type StartActivityMutationVariables = Exact<{
  activity?: Maybe<Period_Type_Enum>;
}>;


export type StartActivityMutation = (
  { __typename?: 'mutation_root' }
  & { insert_activity_period?: Maybe<(
    { __typename?: 'activity_period_mutation_response' }
    & { returning: Array<(
      { __typename?: 'activity_period' }
      & Pick<Activity_Period, 'id' | 'start_time' | 'activity_type_id'>
    )> }
  )> }
);

export type FinishActivityMutationVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
}>;


export type FinishActivityMutation = (
  { __typename?: 'mutation_root' }
  & { update_activity_period?: Maybe<(
    { __typename?: 'activity_period_mutation_response' }
    & Pick<Activity_Period_Mutation_Response, 'affected_rows'>
  )> }
);

export type FinishOngoingActivitiesMutationVariables = Exact<{ [key: string]: never; }>;


export type FinishOngoingActivitiesMutation = (
  { __typename?: 'mutation_root' }
  & { update_activity_period?: Maybe<(
    { __typename?: 'activity_period_mutation_response' }
    & Pick<Activity_Period_Mutation_Response, 'affected_rows'>
  )> }
);

export type AddMeditationMutationVariables = Exact<{
  type: Meditation_Type_Enum;
  date: Scalars['date'];
  start_time: Scalars['timestamptz'];
  end_time: Scalars['timestamptz'];
  repetitions?: Maybe<Scalars['Int']>;
}>;


export type AddMeditationMutation = (
  { __typename?: 'mutation_root' }
  & { insert_meditation?: Maybe<(
    { __typename?: 'meditation_mutation_response' }
    & Pick<Meditation_Mutation_Response, 'affected_rows'>
  )> }
);

export type AllMeditationsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type AllMeditationsSubscription = (
  { __typename?: 'subscription_root' }
  & { meditation: Array<(
    { __typename?: 'meditation' }
    & Pick<Meditation, 'date' | 'meditation_type_id' | 'period_id' | 'repetitions' | 'id'>
  )> }
);

export type StartMeditatingMutationVariables = Exact<{
  meditation_type?: Maybe<Meditation_Type_Enum>;
}>;


export type StartMeditatingMutation = (
  { __typename?: 'mutation_root' }
  & { insert_activity_period?: Maybe<(
    { __typename?: 'activity_period_mutation_response' }
    & Pick<Activity_Period_Mutation_Response, 'affected_rows'>
  )> }
);

export type OngoingMeditationSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OngoingMeditationSubscription = (
  { __typename?: 'subscription_root' }
  & { activity_period: Array<(
    { __typename?: 'activity_period' }
    & { meditations: Array<(
      { __typename?: 'meditation' }
      & Pick<Meditation, 'id' | 'meditation_type_id' | 'repetitions'>
    )> }
  )> }
);

export type SetRepetitionsMutationVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  repetitions?: Maybe<Scalars['Int']>;
}>;


export type SetRepetitionsMutation = (
  { __typename?: 'mutation_root' }
  & { update_meditation?: Maybe<(
    { __typename?: 'meditation_mutation_response' }
    & Pick<Meditation_Mutation_Response, 'affected_rows'>
  )> }
);

export type TotalGuruYogaSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type TotalGuruYogaSubscription = (
  { __typename?: 'subscription_root' }
  & { meditation_aggregate: (
    { __typename?: 'meditation_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'meditation_aggregate_fields' }
      & { sum?: Maybe<(
        { __typename?: 'meditation_sum_fields' }
        & Pick<Meditation_Sum_Fields, 'repetitions'>
      )> }
    )> }
  ) }
);

export type WorkHistorySubscriptionVariables = Exact<{ [key: string]: never; }>;


export type WorkHistorySubscription = (
  { __typename?: 'subscription_root' }
  & { activity_period: Array<(
    { __typename?: 'activity_period' }
    & Pick<Activity_Period, 'start_time' | 'end_time'>
    & { work_units: Array<(
      { __typename?: 'work_unit' }
      & { contractor: (
        { __typename?: 'contractor' }
        & Pick<Contractor, 'name'>
      ) }
    )> }
  )> }
);

export type ContractorsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ContractorsSubscription = (
  { __typename?: 'subscription_root' }
  & { contractor: Array<(
    { __typename?: 'contractor' }
    & Pick<Contractor, 'name' | 'id'>
  )> }
);

export type StartWorkForContractorMutationVariables = Exact<{
  contractor_id?: Maybe<Scalars['Int']>;
}>;


export type StartWorkForContractorMutation = (
  { __typename?: 'mutation_root' }
  & { insert_activity_period?: Maybe<(
    { __typename?: 'activity_period_mutation_response' }
    & Pick<Activity_Period_Mutation_Response, 'affected_rows'>
  )> }
);

export type OngoingWorkSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OngoingWorkSubscription = (
  { __typename?: 'subscription_root' }
  & { activity_period: Array<(
    { __typename?: 'activity_period' }
    & Pick<Activity_Period, 'start_time'>
    & { work_units: Array<(
      { __typename?: 'work_unit' }
      & { contractor: (
        { __typename?: 'contractor' }
        & Pick<Contractor, 'name' | 'hourly_wage'>
      ) }
    )> }
  )> }
);


export const CurrentActivitiesDocument = gql`
    subscription CurrentActivities {
  activity_period(where: {end_time: {_is_null: true}}) {
    id
    start_time
    activity_type_id
  }
}
    `;

/**
 * __useCurrentActivitiesSubscription__
 *
 * To run a query within a React component, call `useCurrentActivitiesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCurrentActivitiesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentActivitiesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCurrentActivitiesSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CurrentActivitiesSubscription, CurrentActivitiesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CurrentActivitiesSubscription, CurrentActivitiesSubscriptionVariables>(CurrentActivitiesDocument, options);
      }
export type CurrentActivitiesSubscriptionHookResult = ReturnType<typeof useCurrentActivitiesSubscription>;
export type CurrentActivitiesSubscriptionResult = Apollo.SubscriptionResult<CurrentActivitiesSubscription>;
export const ActivityHistoryDocument = gql`
    subscription ActivityHistory($activity: period_type_enum) {
  activity_period(
    where: {activity_type_id: {_eq: $activity}, end_time: {_is_null: false}}
  ) {
    id
    start_time
    end_time
  }
}
    `;

/**
 * __useActivityHistorySubscription__
 *
 * To run a query within a React component, call `useActivityHistorySubscription` and pass it any options that fit your needs.
 * When your component renders, `useActivityHistorySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityHistorySubscription({
 *   variables: {
 *      activity: // value for 'activity'
 *   },
 * });
 */
export function useActivityHistorySubscription(baseOptions?: Apollo.SubscriptionHookOptions<ActivityHistorySubscription, ActivityHistorySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ActivityHistorySubscription, ActivityHistorySubscriptionVariables>(ActivityHistoryDocument, options);
      }
export type ActivityHistorySubscriptionHookResult = ReturnType<typeof useActivityHistorySubscription>;
export type ActivityHistorySubscriptionResult = Apollo.SubscriptionResult<ActivityHistorySubscription>;
export const StartActivityDocument = gql`
    mutation StartActivity($activity: period_type_enum) {
  insert_activity_period(objects: {activity_type_id: $activity}) {
    returning {
      id
      start_time
      activity_type_id
    }
  }
}
    `;
export type StartActivityMutationFn = Apollo.MutationFunction<StartActivityMutation, StartActivityMutationVariables>;

/**
 * __useStartActivityMutation__
 *
 * To run a mutation, you first call `useStartActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startActivityMutation, { data, loading, error }] = useStartActivityMutation({
 *   variables: {
 *      activity: // value for 'activity'
 *   },
 * });
 */
export function useStartActivityMutation(baseOptions?: Apollo.MutationHookOptions<StartActivityMutation, StartActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartActivityMutation, StartActivityMutationVariables>(StartActivityDocument, options);
      }
export type StartActivityMutationHookResult = ReturnType<typeof useStartActivityMutation>;
export type StartActivityMutationResult = Apollo.MutationResult<StartActivityMutation>;
export type StartActivityMutationOptions = Apollo.BaseMutationOptions<StartActivityMutation, StartActivityMutationVariables>;
export const FinishActivityDocument = gql`
    mutation FinishActivity($id: Int) {
  update_activity_period(where: {id: {_eq: $id}}, _set: {end_time: "now()"}) {
    affected_rows
  }
}
    `;
export type FinishActivityMutationFn = Apollo.MutationFunction<FinishActivityMutation, FinishActivityMutationVariables>;

/**
 * __useFinishActivityMutation__
 *
 * To run a mutation, you first call `useFinishActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishActivityMutation, { data, loading, error }] = useFinishActivityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFinishActivityMutation(baseOptions?: Apollo.MutationHookOptions<FinishActivityMutation, FinishActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishActivityMutation, FinishActivityMutationVariables>(FinishActivityDocument, options);
      }
export type FinishActivityMutationHookResult = ReturnType<typeof useFinishActivityMutation>;
export type FinishActivityMutationResult = Apollo.MutationResult<FinishActivityMutation>;
export type FinishActivityMutationOptions = Apollo.BaseMutationOptions<FinishActivityMutation, FinishActivityMutationVariables>;
export const FinishOngoingActivitiesDocument = gql`
    mutation FinishOngoingActivities {
  update_activity_period(
    where: {end_time: {_is_null: true}}
    _set: {end_time: "now()"}
  ) {
    affected_rows
  }
}
    `;
export type FinishOngoingActivitiesMutationFn = Apollo.MutationFunction<FinishOngoingActivitiesMutation, FinishOngoingActivitiesMutationVariables>;

/**
 * __useFinishOngoingActivitiesMutation__
 *
 * To run a mutation, you first call `useFinishOngoingActivitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishOngoingActivitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishOngoingActivitiesMutation, { data, loading, error }] = useFinishOngoingActivitiesMutation({
 *   variables: {
 *   },
 * });
 */
export function useFinishOngoingActivitiesMutation(baseOptions?: Apollo.MutationHookOptions<FinishOngoingActivitiesMutation, FinishOngoingActivitiesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishOngoingActivitiesMutation, FinishOngoingActivitiesMutationVariables>(FinishOngoingActivitiesDocument, options);
      }
export type FinishOngoingActivitiesMutationHookResult = ReturnType<typeof useFinishOngoingActivitiesMutation>;
export type FinishOngoingActivitiesMutationResult = Apollo.MutationResult<FinishOngoingActivitiesMutation>;
export type FinishOngoingActivitiesMutationOptions = Apollo.BaseMutationOptions<FinishOngoingActivitiesMutation, FinishOngoingActivitiesMutationVariables>;
export const AddMeditationDocument = gql`
    mutation AddMeditation($type: meditation_type_enum!, $date: date!, $start_time: timestamptz!, $end_time: timestamptz!, $repetitions: Int) {
  insert_meditation(
    objects: {meditation_type_id: $type, date: $date, repetitions: $repetitions, activity_period: {data: {start_time: $start_time, end_time: $end_time, activity_type_id: MEDITATION}}}
  ) {
    affected_rows
  }
}
    `;
export type AddMeditationMutationFn = Apollo.MutationFunction<AddMeditationMutation, AddMeditationMutationVariables>;

/**
 * __useAddMeditationMutation__
 *
 * To run a mutation, you first call `useAddMeditationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMeditationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMeditationMutation, { data, loading, error }] = useAddMeditationMutation({
 *   variables: {
 *      type: // value for 'type'
 *      date: // value for 'date'
 *      start_time: // value for 'start_time'
 *      end_time: // value for 'end_time'
 *      repetitions: // value for 'repetitions'
 *   },
 * });
 */
export function useAddMeditationMutation(baseOptions?: Apollo.MutationHookOptions<AddMeditationMutation, AddMeditationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMeditationMutation, AddMeditationMutationVariables>(AddMeditationDocument, options);
      }
export type AddMeditationMutationHookResult = ReturnType<typeof useAddMeditationMutation>;
export type AddMeditationMutationResult = Apollo.MutationResult<AddMeditationMutation>;
export type AddMeditationMutationOptions = Apollo.BaseMutationOptions<AddMeditationMutation, AddMeditationMutationVariables>;
export const AllMeditationsDocument = gql`
    subscription AllMeditations {
  meditation {
    date
    meditation_type_id
    period_id
    repetitions
    id
  }
}
    `;

/**
 * __useAllMeditationsSubscription__
 *
 * To run a query within a React component, call `useAllMeditationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAllMeditationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMeditationsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAllMeditationsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<AllMeditationsSubscription, AllMeditationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AllMeditationsSubscription, AllMeditationsSubscriptionVariables>(AllMeditationsDocument, options);
      }
export type AllMeditationsSubscriptionHookResult = ReturnType<typeof useAllMeditationsSubscription>;
export type AllMeditationsSubscriptionResult = Apollo.SubscriptionResult<AllMeditationsSubscription>;
export const StartMeditatingDocument = gql`
    mutation StartMeditating($meditation_type: meditation_type_enum) {
  insert_activity_period(
    objects: {meditations: {data: {meditation_type_id: $meditation_type}}, activity_type_id: MEDITATION}
  ) {
    affected_rows
  }
}
    `;
export type StartMeditatingMutationFn = Apollo.MutationFunction<StartMeditatingMutation, StartMeditatingMutationVariables>;

/**
 * __useStartMeditatingMutation__
 *
 * To run a mutation, you first call `useStartMeditatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartMeditatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startMeditatingMutation, { data, loading, error }] = useStartMeditatingMutation({
 *   variables: {
 *      meditation_type: // value for 'meditation_type'
 *   },
 * });
 */
export function useStartMeditatingMutation(baseOptions?: Apollo.MutationHookOptions<StartMeditatingMutation, StartMeditatingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartMeditatingMutation, StartMeditatingMutationVariables>(StartMeditatingDocument, options);
      }
export type StartMeditatingMutationHookResult = ReturnType<typeof useStartMeditatingMutation>;
export type StartMeditatingMutationResult = Apollo.MutationResult<StartMeditatingMutation>;
export type StartMeditatingMutationOptions = Apollo.BaseMutationOptions<StartMeditatingMutation, StartMeditatingMutationVariables>;
export const OngoingMeditationDocument = gql`
    subscription OngoingMeditation {
  activity_period(
    where: {end_time: {_is_null: true}, activity_type_id: {_eq: MEDITATION}}
  ) {
    meditations {
      id
      meditation_type_id
      repetitions
    }
  }
}
    `;

/**
 * __useOngoingMeditationSubscription__
 *
 * To run a query within a React component, call `useOngoingMeditationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOngoingMeditationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOngoingMeditationSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOngoingMeditationSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OngoingMeditationSubscription, OngoingMeditationSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OngoingMeditationSubscription, OngoingMeditationSubscriptionVariables>(OngoingMeditationDocument, options);
      }
export type OngoingMeditationSubscriptionHookResult = ReturnType<typeof useOngoingMeditationSubscription>;
export type OngoingMeditationSubscriptionResult = Apollo.SubscriptionResult<OngoingMeditationSubscription>;
export const SetRepetitionsDocument = gql`
    mutation SetRepetitions($id: Int, $repetitions: Int) {
  update_meditation(where: {id: {_eq: $id}}, _set: {repetitions: $repetitions}) {
    affected_rows
  }
}
    `;
export type SetRepetitionsMutationFn = Apollo.MutationFunction<SetRepetitionsMutation, SetRepetitionsMutationVariables>;

/**
 * __useSetRepetitionsMutation__
 *
 * To run a mutation, you first call `useSetRepetitionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRepetitionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRepetitionsMutation, { data, loading, error }] = useSetRepetitionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      repetitions: // value for 'repetitions'
 *   },
 * });
 */
export function useSetRepetitionsMutation(baseOptions?: Apollo.MutationHookOptions<SetRepetitionsMutation, SetRepetitionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetRepetitionsMutation, SetRepetitionsMutationVariables>(SetRepetitionsDocument, options);
      }
export type SetRepetitionsMutationHookResult = ReturnType<typeof useSetRepetitionsMutation>;
export type SetRepetitionsMutationResult = Apollo.MutationResult<SetRepetitionsMutation>;
export type SetRepetitionsMutationOptions = Apollo.BaseMutationOptions<SetRepetitionsMutation, SetRepetitionsMutationVariables>;
export const TotalGuruYogaDocument = gql`
    subscription TotalGuruYoga {
  meditation_aggregate(where: {meditation_type_id: {_eq: GURU_YOGA}}) {
    aggregate {
      sum {
        repetitions
      }
    }
  }
}
    `;

/**
 * __useTotalGuruYogaSubscription__
 *
 * To run a query within a React component, call `useTotalGuruYogaSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTotalGuruYogaSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalGuruYogaSubscription({
 *   variables: {
 *   },
 * });
 */
export function useTotalGuruYogaSubscription(baseOptions?: Apollo.SubscriptionHookOptions<TotalGuruYogaSubscription, TotalGuruYogaSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TotalGuruYogaSubscription, TotalGuruYogaSubscriptionVariables>(TotalGuruYogaDocument, options);
      }
export type TotalGuruYogaSubscriptionHookResult = ReturnType<typeof useTotalGuruYogaSubscription>;
export type TotalGuruYogaSubscriptionResult = Apollo.SubscriptionResult<TotalGuruYogaSubscription>;
export const WorkHistoryDocument = gql`
    subscription WorkHistory {
  activity_period(
    where: {activity_type_id: {_eq: WORK}, end_time: {_is_null: false}}
    order_by: {start_time: asc}
  ) {
    work_units {
      contractor {
        name
      }
    }
    start_time
    end_time
  }
}
    `;

/**
 * __useWorkHistorySubscription__
 *
 * To run a query within a React component, call `useWorkHistorySubscription` and pass it any options that fit your needs.
 * When your component renders, `useWorkHistorySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkHistorySubscription({
 *   variables: {
 *   },
 * });
 */
export function useWorkHistorySubscription(baseOptions?: Apollo.SubscriptionHookOptions<WorkHistorySubscription, WorkHistorySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<WorkHistorySubscription, WorkHistorySubscriptionVariables>(WorkHistoryDocument, options);
      }
export type WorkHistorySubscriptionHookResult = ReturnType<typeof useWorkHistorySubscription>;
export type WorkHistorySubscriptionResult = Apollo.SubscriptionResult<WorkHistorySubscription>;
export const ContractorsDocument = gql`
    subscription Contractors {
  contractor {
    name
    id
  }
}
    `;

/**
 * __useContractorsSubscription__
 *
 * To run a query within a React component, call `useContractorsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useContractorsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractorsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useContractorsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ContractorsSubscription, ContractorsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ContractorsSubscription, ContractorsSubscriptionVariables>(ContractorsDocument, options);
      }
export type ContractorsSubscriptionHookResult = ReturnType<typeof useContractorsSubscription>;
export type ContractorsSubscriptionResult = Apollo.SubscriptionResult<ContractorsSubscription>;
export const StartWorkForContractorDocument = gql`
    mutation StartWorkForContractor($contractor_id: Int) {
  insert_activity_period(
    objects: {work_units: {data: {contractor_id: $contractor_id}}, activity_type_id: WORK}
  ) {
    affected_rows
  }
}
    `;
export type StartWorkForContractorMutationFn = Apollo.MutationFunction<StartWorkForContractorMutation, StartWorkForContractorMutationVariables>;

/**
 * __useStartWorkForContractorMutation__
 *
 * To run a mutation, you first call `useStartWorkForContractorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartWorkForContractorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startWorkForContractorMutation, { data, loading, error }] = useStartWorkForContractorMutation({
 *   variables: {
 *      contractor_id: // value for 'contractor_id'
 *   },
 * });
 */
export function useStartWorkForContractorMutation(baseOptions?: Apollo.MutationHookOptions<StartWorkForContractorMutation, StartWorkForContractorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartWorkForContractorMutation, StartWorkForContractorMutationVariables>(StartWorkForContractorDocument, options);
      }
export type StartWorkForContractorMutationHookResult = ReturnType<typeof useStartWorkForContractorMutation>;
export type StartWorkForContractorMutationResult = Apollo.MutationResult<StartWorkForContractorMutation>;
export type StartWorkForContractorMutationOptions = Apollo.BaseMutationOptions<StartWorkForContractorMutation, StartWorkForContractorMutationVariables>;
export const OngoingWorkDocument = gql`
    subscription OngoingWork {
  activity_period(
    where: {end_time: {_is_null: true}, activity_type_id: {_eq: WORK}}
  ) {
    start_time
    work_units {
      contractor {
        name
        hourly_wage
      }
    }
  }
}
    `;

/**
 * __useOngoingWorkSubscription__
 *
 * To run a query within a React component, call `useOngoingWorkSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOngoingWorkSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOngoingWorkSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOngoingWorkSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OngoingWorkSubscription, OngoingWorkSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OngoingWorkSubscription, OngoingWorkSubscriptionVariables>(OngoingWorkDocument, options);
      }
export type OngoingWorkSubscriptionHookResult = ReturnType<typeof useOngoingWorkSubscription>;
export type OngoingWorkSubscriptionResult = Apollo.SubscriptionResult<OngoingWorkSubscription>;