import React from 'react'
import firebase, { AuthInterface } from '../firebase'
import { useAuthState } from 'react-firebase-hooks/auth';

export const userContext = React.createContext<{user: firebase.User|null}>({
    user: null
})

export const useSession = () => {
    const { user } = React.useContext(userContext)
    return user
}

export const AuthProvider: React.FC<{}> = ({children}) => {
    const [user, loading] = useAuthState(firebase.auth());

    if (loading) {
        return <div>
            Initializing app...
        </div>
    } else if (!user) {
        return <AuthInterface />
    } else {
        return <userContext.Provider value={{user}}>
            {children}
        </userContext.Provider>
    }
}