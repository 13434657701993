import React from 'react'

import firebase from "firebase/app";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import "firebase/auth";
 
const firebaseConfig = {
    apiKey: "AIzaSyA83MDju9t7g8eXS-ait9Q4Hoel4mVI1gA",
    authDomain: "kypninja.firebaseapp.com",
    databaseURL: "https://kypninja.firebaseio.com",
    projectId: "kypninja",
    storageBucket: "kypninja.appspot.com",
    messagingSenderId: "843574673425",
    appId: "1:843574673425:web:2497bbf1779315bb7271b0",
    measurementId: "G-WFDKN7289L"
  };
  
firebase.initializeApp(firebaseConfig);

// Configure FirebaseUI.
const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ]
  };

export default firebase

export const AuthInterface = () => <div>    
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
</div>

const auth = firebase.auth()

export {auth}