import React from 'react'
import { useStartActivityMutation, Period_Type_Enum, useActivityHistorySubscription, useCurrentActivitiesSubscription } from '../generated/graphql'
import styled from 'styled-components'
import { Button } from 'antd'
import moment from 'moment'

const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
`

export const Body: React.FC<{}> = () => {

    const {data} = useActivityHistorySubscription({variables: {activity: Period_Type_Enum.Qigong}})
    const {data: ongoing} = useCurrentActivitiesSubscription()
    const [mutate, mutstat] = useStartActivityMutation({variables: {activity: Period_Type_Enum.Qigong}})

    const canmutate = ongoing && ongoing.activity_period.length === 0 && !mutstat.loading
    console.log(ongoing)


    return <BodyWrapper>
        Last activities:
        {data?.activity_period.map(p => {
            const start = moment(p.start_time)
            const period = moment.duration(start.diff(p.end_time))

            return <div>
                {start.toDate().toLocaleDateString()} - {period.humanize()}
            </div>
        })}
        <Button type="primary" disabled={!canmutate} onClick={() => mutate()}>Start tracking Qigong</Button>
    </BodyWrapper>
}