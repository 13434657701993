import { ApolloClient, InMemoryCache } from '@apollo/client';
import {WebSocketLink} from '@apollo/client/link/ws'
import {onError} from '@apollo/client/link/error'
import {auth} from './firebase'

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      console.log(networkError);
    }
  });
  

export const getHeaders = async () => {
    let token = ""
    const currentUser = auth.currentUser

    if (currentUser) {
        try {
            token = await currentUser.getIdToken()
        }
        catch (e) {

        }
    }
    return {
        authorization: token ? `Bearer ${token}` : ""
    }
}

const websocketLink = new WebSocketLink({
    uri: 'wss://api.plas.app/v1/graphql',
    options: {
        reconnect: true,
        connectionParams: async () => getHeaders().then(headers => ({
            headers
        })),
        lazy: true
    },
})


export const client = new ApolloClient({
    link: errorLink.concat(websocketLink),
    cache: new InMemoryCache()
  });