import React from 'react'
import { useCurrentActivitiesSubscription, useFinishOngoingActivitiesMutation, Period_Type_Enum } from '../generated/graphql'
import styled from 'styled-components'
import { Button } from 'antd'
import moment from 'moment'
import { useTime } from '../hooks/useTime'
import { OngoingWorkExtra } from './OngoingExtra'
import { OngoingMeditation } from './OngoingMeditation'

const OngoingWrapperWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const OngoingWrapper = styled.div`
    width: 100%;
    display: flex;
    font-size: 16px;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 10px;
    padding-top: 10px;
    background-color: orange;
    
`

const Info = styled.div`

`
const TimeInfo = styled.div`

`

export const OngoingInfo: React.FC<{}> = () => {
    const {data} = useCurrentActivitiesSubscription()
    const [mutate] = useFinishOngoingActivitiesMutation()

    const activity = data?.activity_period?.[0]
    
    const now = useTime(10000)

    if (!activity) return <div />

    const start_time = moment(activity.start_time)    
    const diff = moment.duration(now.diff(start_time))
    const human = diff.humanize()
  
    return <OngoingWrapperWrapper>
        {
            activity.activity_type_id === Period_Type_Enum.Meditation &&
                <OngoingMeditation />
        }
        <OngoingWrapper>
        <Info>
            Ongoing activity: {activity.activity_type_id}.
            <TimeInfo>
                Time elapsed: {human}
            </TimeInfo>
            {activity.activity_type_id === Period_Type_Enum.Work &&
                <OngoingWorkExtra />
            }
        </Info>
        <Button type="dashed" onClick={() => mutate()}>Stop tracking</Button>
    </OngoingWrapper>
    </OngoingWrapperWrapper>
}