import React from 'react'
import styled from 'styled-components'
import { useCurrentActivitiesSubscription, useStartWorkForContractorMutation, useContractorsSubscription, useWorkHistorySubscription } from '../generated/graphql'
import moment from 'moment'
import { Button } from 'antd'

const WorkWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
`

const EngageWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    & > button {
    }
`

const Divider = styled.hr`

`

export const Work: React.FC<{}> = () => {
    const {data} = useWorkHistorySubscription()
    const {data: ongoing} = useCurrentActivitiesSubscription()
    const {data: contractors} = useContractorsSubscription()
    const [mutate, mutstate] = useStartWorkForContractorMutation()

    const canwork = !mutstate.loading && ongoing && ongoing.activity_period.length === 0

    const startWorkFor = (id: number) => {
        mutate({variables: {contractor_id: id}})
    }

    return <WorkWrapper>
        Latest work units:
        {
            data?.activity_period.map(p => <div>
                {moment(p.start_time).toDate().toLocaleDateString()} - 
                {moment(p.start_time).toDate().toLocaleTimeString()} to {moment(p.end_time).toDate().toLocaleTimeString()} - 
                {p.work_units[0].contractor.name} - {moment.duration(moment(p.end_time).diff(moment(p.start_time))).asHours().toFixed(2)}
            </div>)
        }
        <Divider />
        <EngageWrapper>
            {contractors?.contractor.map(c => <Button type="primary" disabled={!canwork} onClick={() => startWorkFor(c.id)} >
                Start work for {c.name}
            </Button>)}
        </EngageWrapper>
    </WorkWrapper>
}