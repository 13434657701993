import React from 'react'
import { useOngoingMeditationSubscription, useSetRepetitionsMutation } from '../generated/graphql'
import styled from 'styled-components'
import { Statistic, Button } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'

const Wrapper = styled.div`
    background-color: #FDCA40;
    font-size: 16px;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const SingleItem = styled.div`
    margin-left: 10px;
    margin-right: 10px;
`

export const OngoingMeditation: React.FC<{}> = () => {
    const {data: meditation} = useOngoingMeditationSubscription()
    const [mutate] = useSetRepetitionsMutation()

    const medi = meditation?.activity_period[0]?.meditations[0]
    const count = medi?.repetitions || 0
    console.log(count)
    const increment = () => mutate({variables: {id: medi?.id, repetitions: count + 108}})

    return <Wrapper>
        <SingleItem>
            {medi?.meditation_type_id}:
        </SingleItem>
        <SingleItem>
            <Statistic value={count} />
        </SingleItem>
        <SingleItem>
            <Button  shape="circle" icon={<PlusCircleOutlined />} onClick={increment} />
        </SingleItem>
    </Wrapper>
}