import React from 'react'
import { useCurrentActivitiesSubscription, useStartMeditatingMutation, Meditation_Type_Enum, useTotalGuruYogaSubscription } from '../generated/graphql'
import styled from 'styled-components'
import { Button, Statistic } from 'antd'
import { AddSession } from './AddSession'
import moment from 'moment'

const MeditationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    position: relative;
    height: 100%;
`

const EngageWrapper = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    & > button {
        margin-top: 10px;
    }
`

export const CURRENT_MEDITATIONS = [
    Meditation_Type_Enum.Prostrations,
    Meditation_Type_Enum.SixteenthKarmapa,
    Meditation_Type_Enum.GuruYoga,
]

export const Meditations: React.FC<{}> = () => {
    const {data} = useTotalGuruYogaSubscription()
    const {data: ongoing} = useCurrentActivitiesSubscription()
    const [mutate, mutstat] = useStartMeditatingMutation()
    const [revealed, setRevealed] = React.useState(false)
    
    const canmutate = ongoing && ongoing.activity_period.length === 0 && !mutstat.loading

    const total = data?.meditation_aggregate.aggregate?.sum?.repetitions || 0

    const remaining = 111111 - total
    const days_5 = remaining / (5*108)
    const days_10 = remaining / (10*108)
    const finish_5 = moment().add(days_5, "days")
    const finish_10 = moment().add(days_10, "days")

    const totstat = revealed ? total : Math.floor(total/1000)

    return <MeditationWrapper>
        Total Guru Yoga:
        <div onDoubleClick={() => setRevealed(rev => !rev)}>
            <Statistic value={totstat} />
        </div>
        { revealed && 
            <Statistic value={(total/111111*100).toFixed(2)+"%"} />
        }
        <EngageWrapper>
            {CURRENT_MEDITATIONS.map(m => <Button disabled={!canmutate} onClick={() => mutate({variables: {meditation_type: m}})} >
                Start {m}
            </Button>)}
        </EngageWrapper>
        {revealed && <>
        <br />
        5x prediction: {finish_5.toDate().toLocaleDateString()}
        <br />
        10x prediction: {finish_10.toDate().toLocaleDateString()}
        </>}
        <AddSession />
    </MeditationWrapper>
}
